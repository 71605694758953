<template>
  <div class="w-step">
    <div class="step-3">
      <div class="summary">
        <div class="step-3__title">Your Stay Summary</div>
        <div class="summary__block">
          <div class="summary__block--unit">
            <span>Arrival:</span>
            {{ searchParams.arrival }}
          </div>
          <div class="summary__block--unit">
            <span>Departure:</span>
            {{ searchParams.departure }}
          </div>
        </div>
        <div class="summary__block">
          <span>Guest & Rooms:</span>
          {{ searchParams.adults }}
          {{ searchParams.adults > 1 ? "Adults" : "Adult" }},
          {{ searchParams.children }}
          {{ searchParams.children > 1 ? "Children" : "Child" }},
          {{ searchParams.rooms }}
          {{ searchParams.rooms > 1 ? "Rooms" : "Room" }}
        </div>
        <div class="summary__block">
          <div
            v-for="(r, idx) in selectedRoom"
            :key="idx"
            :class="
              !r.isValid ? 'summary__block--room error' : 'summary__block--room'
            "
          >
            <!-- <img :src="icePortal.getImages(r.code, 'thumb')[0]" alt=""/> -->
            <div
              :style="
                `background-image:url(` +
                icePortal.getImages(r.code, 'full')[0] +
                `)`
              "
              class="room_image"
            ></div>
            <div class="summary__block--room-summary">
              <span class="summary__block--room-name">Room {{ r.name }}</span>
              <p>Max Occupancy {{ r.max_occupancy }}</p>
              <p>Amount before tax: ${{ r.total.AmountBeforeTax }}</p>
              <div
                v-if="searchParams.rooms > 1"
                class="summary__block--room-guests"
              >
                <div>
                  <label for="summary-adults">Adults</label>
                  <select
                    id="summary-adults"
                    name="summary-adults"
                    v-model="r.adults"
                    @change="validateRoom(r)"
                  >
                    <!-- <option value="0" v-if="idx > 0">0</option> -->
                    <option v-for="n in r.max_occupancy" :key="n">
                      {{ n }}
                    </option>
                  </select>
                  <span class="error"></span>
                </div>
                <div >
                  <label for="summary-children">Children</label>
                  <select
                    id="summary-children"
                    name="summary-children"
                    v-model="r.children"
                    @change="validateRoom(r)"
                  >
                    <option>0</option>
                    <option v-for="n in r.max_occupancy - r.adults" :key="n">
                      {{ n }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="summary__block">
          <p class="summary__block--small">
            * {{ rateDetailsInfo.guarantee.text }}
          </p>

          <div class="summary__block policy">
            <span>Rate Description</span>
            <ul>
              <li>{{ rateDetailsInfo.description }}</li>
            </ul>
          </div>

          <div class="summary__block policy">
            <span>Additional Details</span>
            <ul>
              <li v-for="(text, idx) in rateDetailsInfo.additionalDetails" :key="idx">{{ text }}</li>
            </ul>
          </div>

          <div class="summary__block policy">
            <span>Cancelation policy</span>
            <ul>
              <li>Refundable: {{ !rateDetailsInfo.cancelPenalties.NonRefundable ? 'Yes' : 'No'  /*ratePlans[0].cancellation.text */ }}</li>
              <li>{{ rateDetailsInfo.cancelPenalties.text }}</li>
            </ul>
          </div>
        </div>

        <div class="summary__block policy">
            <span>Hotel Alerts</span>
            <ul>
              <li v-for="(el, idx) in getHotelAlerts" :key="idx">{{ el.text }}</li>
            </ul>
          </div>
        
        <div class="summary__block">
          <div class="clearfix">
            <div class="summary__total--label summary__total--label-small">Total (before tax)</div>
            <div class="summary__total--price">${{ parseFloat(this.getTotalAmount().beforeTax).toFixed(2) }}</div>
          </div>
          <div class="clearfix" v-if="this.getTotalAmount().fees > 0">
            <div class="summary__total--label summary__total--label-small">Fees</div>
            <div class="summary__total--price">${{ (parseFloat(this.getTotalAmount().fees) * numberOfNights()).toFixed(2) }}</div>
          </div>
          <div class="clearfix">
            <div class="summary__total--label summary__total--label-small">Taxes</div>
            <div class="summary__total--price">${{ parseFloat(this.getTotalAmount().taxes).toFixed(2) }}</div> 
          </div>
        </div>

        <!-- ############# TOFIX ######### -->
        <div class="summary__total clearfix">
          <div class="summary__total--label">Subtotal</div>
          <div class="summary__total--price total">${{ parseFloat(this.getTotalAmount().afterTax).toFixed(2) }}</div>
        </div>

      </div>
      <div v-if="bookingStep == 'Initiate'" class="guest-info" id="guestinfo">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <div class="step-3__title">Guest Information</div>
          <div class="flex">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry-medium"
            >
              <label for="first-name-payment" class="hidden">First Name</label>
              <input
                id="first-name-payment"
                name="first-name-payment"
                type="text"
                placeholder="First Name"
                v-model="guestData.givenName"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry-medium"
            >
              <label for="last-name-payment" class="hidden">Last Name</label>
              <input
                id="last-name-payment"
                name="last-name-payment"
                type="text"
                placeholder="Last Name"
                v-model="guestData.surName"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              rules="email|required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry-large"
            >
              <label for="email-address-payment" class="hidden">Email Address</label>
              <input
                id="email-address-payment"
                name="email-address-payment"
                type="text"
                placeholder="Email Address"
                v-model="guestData.email"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry-large"
            >
              <label for="address-payment" class="hidden">Address</label>
              <input
                id="address-payment"
                name="address-payment"
                type="text"
                placeholder="Address 1"
                v-model="guestData.address1"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

            <div class="w-entry w-entry-small">
              <label for="apt-suite-payment" class="hidden">Apt/Suite</label>
              <input
                id="apt-suite-payment"
                name="apt-suite-payment"
                type="text"
                placeholder="Apt/Suite"
                v-model="guestData.address2"
              />
            </div>

            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry-small city-payment"
            >
              <label for="city-payment" class="hidden">City</label>
              <input name="city-payment" id="city-payment" type="text" placeholder="City" v-model="guestData.city" />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry-small state-payment"
            >
              <label for="state-payment" class="hidden">State</label>
              <input v-show="guestData.country !== 'US'" id="state-payment" name="state-payment" type="text" placeholder="State" v-model="guestData.state" />
              <select v-show="guestData.country == 'US'" v-model="guestData.state">
                <option value="">State</option>
                <option :value="state.value" v-for="state in usStates" :key="state.value">{{ state.text }}</option>
              </select>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry-large"
            >
              <label for="country-payment" class="hidden">Country</label>
              <select name="country-payment" id="country-payment" v-model="guestData.country" placeholder="Country">
                <option value="">Country</option>
                <option value="US">United States</option>
                <option v-for="[code, name] in Object.entries(countryList)" :key="code" :value="code.toUpperCase()">{{ name }}</option>
              </select>
              
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry-small"
            >
              <label for="zip-payment" class="hidden">Zip Code</label>
              <input
                id="zip-payment"
                name="zip-payment"
                type="text"
                placeholder="Zip Code"
                v-model="guestData.postalCode"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              rules="MemberIdValidator"
              v-slot="{ errors }"
              tag="div"
              class="w-entry w-entry"
            >
              <label for="choice-member-payment" class="hidden"><!--Choice Privileges-->Membership Number</label>
              <input
                id="choice-member-payment"
                name="choice-member-payment"
                type="text"
                placeholder="Membership Number"
                maxlength="12"
                v-on:keypress="isAlphaNum($event)"
                v-model="guestData.memberNumber"
              />
                <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="step-3__title">Payment Information</div>

          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            tag="div"
            class="w-entry w-entry-xlarge"
          >
            <label for="cardholder-name-payment" class="hidden">Card Holder Name</label>
            <input
              id="cardholder-name-payment"
              name="cardholder-name-payment"
              type="text"
              placeholder="Card Holder Name"
              v-model="paymentData.card_holder"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="flex">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry payment-required w-entry-large"
            >
              <label for="card_number" class="hidden">Card Number</label>
              <div id="cc-number" class="form-field"></div>
              <input type="hidden" name="ccNumberValid" id="ccNumberValid" ref="ccNumberValid" v-model="paymentData.card_valid" >
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry payment-required w-entry-medium"
            >
              <label for="exp-month-payment" class="hidden">Expiration Month</label>
              <select name="exp-month-payment" id="exp-month-payment" v-model="paymentData.expireDateMonth">
                <option value="" disabled selected>Exp Month</option>
                <option
                  :value="month | twoDigits"
                  v-for="month in 12"
                  :key="month"
                >
                  {{ month | toMonthName }}
                </option>
              </select>
              <!-- <input type="text" placeholder="month" max="2" v-model="paymentData.expireDateMonth"> -->
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              tag="div"
              class="w-entry payment-required w-entry-medium"
            >
              <label for="exp-year-payment" class="hidden">Exp Year</label>
              <select
                name="exp-year-payment"
                id="exp-year-payment"
                v-model="paymentData.expireDateYear"
                placeholder="test"
              >
                <option value="" disabled selected>Exp Year</option>
                <option
                  :value="year.short"
                  v-for="year in yearsArray"
                  :key="year.short"
                >
                  {{ year.long }}
                </option>
              </select>
              <!-- <input type="text" placeholder="year" max="2" v-model="paymentData.expireDateYear"> -->
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div v-if="processing"> 
            <div class="step-3__processing"><div class="loader-booking"></div>Processing...</div>
          </div>
          <div v-if="!$isMobile()">
            <div class="step-3__cta-section">
              <a
                class="modify"
                href=""
                @click.prevent="$emit('go-to-tab','selectRoom')"
                >Modify</a
              >
              <button
                class="cta"
                @click="bookNow()"
                :disabled="invalid || processing"
              >
                Book Now
              </button>
              <!-- <p class="error" v-if="bookingError">{{ bookingError }}</p> -->
            </div>
          </div>  
        </ValidationObserver>
      </div>

      <div v-if="bookingStep == 'Pending'" class="w-step guest-info">
        <ul>
          <!-- <li>Confirmation Number: {{ bookingSummary.confirmation_id }}</li> -->
          <li>First Name: {{ bookingSummary.first_name }}</li>
          <li>Last Name: {{ bookingSummary.last_name }}</li>
          <li>Checkin: {{ bookingSummary.checkin }}</li>
          <li>Checkout: {{ bookingSummary.checkout }}</li>
          <li>Adults:</li>
          <li>Children:</li>
          <li>Rooms:</li>
          <li>Total:</li>
        </ul>
        <button @click.prevent="confirmBooking">Confirm Booking</button>
      </div>

      <div v-if="bookingStep == 'Committed'" class="guest-info guest-info__confirmation" id="divBookingConfirmation">
        <div class="summary__guests">
          <div class="step-3__title">Your Reservation is <span>Confirmed</span></div>
          <ul class="step-3__confirmtion-info"> 
            <li>
              <span>Confirmation Number</span
              >{{ bookingSummary.reservationID }}
            </li>
            <li><span>Checkin</span>{{ bookingSummary.timespan.Start | formatDate }}</li>
            <li><span>Checkout</span>{{ bookingSummary.timespan.End | formatDate }}</li>
            <li><span>First Name</span>{{ bookingSummary.customer.PersonName.GivenName }}</li>
            <li><span>Last Name</span>{{ bookingSummary.customer.PersonName.Surname }}</li>
          </ul>
        </div>
        
        <ul class="step-3__list step-3__list--total">
          <li>
            <span>Total: </span>${{ bookingSummary.total.amountAfterTax }}
          </li>
        </ul> 

        <div class="step-3__print-ctas">
          <!-- <div v-if="processing"><div class="loader-booking"></div>Processing...</div> -->
          <div class="step-3__print-ctas--reservations">
            <a href="#" @click.prevent="printReservation" class="cta step-3__print cta">Print Reservation</a>
            <a href="#" @click.prevent="cancelReservation" class="step-3__cancel">Cancel Reservation</a>
          </div>
        </div>


        <!-- //Print Preview -->
        <div id="divPrintPreview" style="display:none">
          <div>
            <h2>{{ getHotelName }}</h2>  
            <h3>Reservation Summary:</h3>
            <ul class=""> 
              <li><span>Confirmation Number:</span>{{ bookingSummary.reservationID }}</li>
              <li><span>First Name:</span>{{ bookingSummary.customer.PersonName.GivenName }}</li>
              <li><span>Last Name:</span>{{ bookingSummary.customer.PersonName.Surname }}</li>
              <li><span>Total: </span>${{ bookingSummary.total.amountAfterTax }}</li>
            </ul>

            <h3>Stay Summary:</h3>
            <ul class=""> 
              <li><span>Checkin:</span>{{ bookingSummary.timespan.Start | formatDate }}</li>
              <li><span>Checkout:</span>{{ bookingSummary.timespan.End | formatDate }}</li>
              <li><span>Guest & Rooms:</span>
                {{ searchParams.adults }}
                {{ searchParams.adults > 1 ? "Adults" : "Adult" }},
                {{ searchParams.children }}
                {{ searchParams.children > 1 ? "Children" : "Child" }},
                {{ searchParams.rooms }}
                {{ searchParams.rooms > 1 ? "Rooms" : "Room" }}
              </li>
            </ul>
            <div>
              <h3>Room:</h3>
              <div v-for="(r, idx) in selectedRoom" :key="idx">
                <img :src="icePortal.getImages(r.code, 'thumb')[0]" alt="">
                <ul>
                  <li><span>Room </span>{{ r.name }}</li>
                  <li><span>Max Occupancy </span> {{ r.max_occupancy }}</li>
                  <li><span>Amount before tax </span> ${{ r.total.AmountBeforeTax }}</li>
                </ul>

                <div class="">
                  <h4>Additional Details</h4>
                  <ul>
                    <li v-for="(text, idx) in rateDetailsInfo.additionalDetails" :key="idx">{{ text }}</li>
                  </ul>
                </div>

                <div class="">
                  <h4>Cancelation policy</h4>
                  <ul>
                    <li>Refundable: {{ !rateDetailsInfo.cancelPenalties.NonRefundable ? 'Yes' : 'No'  /*ratePlans[0].cancellation.text */ }}</li>
                    <li>{{ rateDetailsInfo.cancelPenalties.text }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="bookingStep == 'Cancelled'" class="guest-info">
        <p>Your reservation has been cancelled successfully.</p>
        <p>Click <a href="" @click.prevent="startOver">here to start over</a> or <a href="" @click.prevent="close">close</a> this window.</p>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { calendarMixins } from "../mixins/calendarMixins";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
//import { Validator } from 'vee-validate';

import { getCodeList } from 'country-list'
import { states } from '@/static/states'

import { bookingWidgetMixins } from '../mixins/bookingWidgetMixins'

import ChoiceBookingAPI from "@/api/ChoiceBookingAPI"



// MemberID validation
extend('MemberIdValidator', (value) => {
	const memberIdRegex = new RegExp(
		'^(((GP)?)||((GP-)?))([A-z]{3})([0-9]{4,6})$'
	);

	return memberIdRegex.test(value) || "*Invalid member account number";
});



//Validation
extend("date", {
  validate: (value) => {
    const todaysDate = new Date();
    const selectedCheckin = new Date(value);
    return selectedCheckin > todaysDate;
  },
});

extend("required", {
  ...required,
  message: "*Required field",
});

extend("email", {
  ...email,
  message: "*Invalid email address ",
});

export default {
  name: "Checkout",
  mixins: [bookingWidgetMixins, calendarMixins],
  props: ['icePortal'],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapState(["searchParams", "selectedRoom", "ratePlans","rateDetailsInfo","guestData","paymentData"]),
    ...mapGetters(["getRateDetails","rateDetailsInfo","getHotelName","getHotelAlerts"]),
    countryList(){ 
      const countryList = getCodeList() 
      delete countryList.us
      return countryList

    },
    usStates() {
      return states
    },

    // numberOfNights(){
    //     var date1 = new Date(this.searchParams.arrival);
    //     var date2 = new Date(this.searchParams.departure);
    //     var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    //     return Math.ceil(timeDiff / (1000 * 3600 * 24)); 
    // },

    yearsArray() {
      const d = new Date();
      const year = d.getFullYear();
      let years = [];

      for (let i = year; i < year + 10; i++) {
        years.push({
          short: i.toString().slice(-2),
          long: i,
        });
      }

      return years;
    },
  },

  created() {
    setTimeout(() => { this.initVGS() }, 1000);
    setTimeout(() => {
      document.getElementById('booking-widget-container').scrollTo(0, -57)
    }, 100);
  },

  mounted(){
    this.$root.$on("bookNow", () => {
      this.bookNow()
    })

    this.$root.$on("validateCheckoutForm", async () => {
      const isValid = await this.$refs.observer.validate();
      this.$root.$emit('isCheckoutFormValid', isValid)
      
    })
  },

  watch: {
    'state.card_number': function(newVal, oldVal) {

      if(newVal.isValid){
        try {
          const cardType = this.acceptedCC.find( card => card.vgs == newVal.cardType )
          this.paymentData.card_code = cardType.code 
          this.paymentData.card_valid = 'yes'
        } catch (error) {
          console.error(`Credit card type ${newVal.cardType} not found`);
          if(this.paymentData.card_valid != 'no') this.$noty.error(`Credit card ${newVal.cardType} is not accepted.`)
          this.paymentData.card_valid = 'no';
        }
      } else {
        this.paymentData.card_valid = ''
      }
    }
  },

  data() {
    return {      
      acceptedCC: this.getCreditCardList(),
      bookingStep: "Initiate",
      bookingTransactionID: "",
      processing: false,
      state: {},
      // guestData: {
      //   givenName: "",
      //   surName: "",
      //   email: "",
      //   address1: "",
      //   address2: "",
      //   city: "",
      //   state: "",
      //   country: "US",
      //   postalCode: "",
      //   memberNumber: "",
      // },
      // paymentData: {
      //   // cardCode: "AX",
      //   // cardNumber: "371449635398431",
      //   // expireDate: "1220",
      //   // cardHolderName: "John Doe"
      //   expireDateMonth: "",
      //   expireDateYear: "",
      //   card_valid:""
      // },
      bookingError: null,
      bookingSummary: {},
    };
  },

  methods: {
    ...mapGetters(['getTotalAmount']),

    bookNowDisabled(){
      const temp = this.invalid ||
        typeof this.state.card_number == 'undefined' ||
        !this.state.card_number.isValid ? true : false

      if (temp) this.$root.$emit('bookNowDisabled', true)  
      else this.$root.$emit('bookNowDisabled', true)  

      return temp

    },

    async validateRoom(room) {
      
      //update RoomRateDetails

      const params = {
        roomTypeCode: '',
        adults: '',
        children: ''
      }

      const resp = await ChoiceBookingAPI.getRoomRateDetails(room, this.searchParams)

      return true

      // room.isValid = parseInt(room.max_occupancy) >= (parseInt(room.adults) + parseInt(room.children))

      // const totalGuestAllRooms = this.totalGuestsAllRooms

      // let adultsTotal = 0
      // let childrenTotal = 0
      // this.bookingData.selectedRoom.forEach(room => {
      //   adultsTotal = adultsTotal + parseInt(room.adults)
      //   childrenTotal = childrenTotal + parseInt(room.children)
      // })
      // if (adultsTotal !== parseInt(this.bookingData.adults)) {
      //   this.bookingData.adults = adultsTotal
      // }
      // if (childrenTotal !== parseInt(this.bookingData.children)) {
      //   this.bookingData.children = childrenTotal
      // }


    },

    initVGS(){

      const styles = { 
        'background': '#f2f2f2',
        'padding': '0 0 0 10px',
        'color': '#7f7f7f',
        'font-size': '13.5px', 
        'font-family': 'Arial',
        'position': 'relative', 
        'overflow': 'hidden',
        'width': '95%',
        'height': '36px'  
      }
      
      if (IS_LIVE) {
        this.form = VGSCollect.create("tntxhb9yjxe", "live", (state) => { 
          this.state = state
        });
      } else {
        this.form = VGSCollect.create("tnt5aufdbg3", (state) => { 
          this.state = state
        });
      }
      

      this.form.field('#cc-number', {
        type: 'card-number',
        name: 'card_number',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: 'Card number',
        showCardIcon: false,
        validations: ['required', 'validCardNumber'],
        css: styles,
      });

    },

    async bookNow(){
      if (!this.state.card_number.isValid) {
        alert('Card Number invalid!')
        return;
      }

      // if (this.totalGuestsAllRooms.adults > this.bookingData.adults || this.totalGuestsAllRooms.children > this.bookingData.children ) {
      //   console.log('Please verify the number of guest per room')
      //   return 
      // }
      
      this.processing = true;
      this.bookingError = null
      
      this.form.submit('/post', {}, this.processBooking)
    },

    async processBooking(status, response){
      if(status == 200) {
        // console.log("Credit Card validated")
        //validate credit card info
        
        this.paymentData.card_exp = this.paymentData.expireDateMonth + this.paymentData.expireDateYear
        this.paymentData.card_number = (response.card_number) ? response.card_number : response.json.card_number
      
        const data = {
          selectedRoom: this.selectedRoom,
          bookingData: this.searchParams,
          guestData: this.guestData,
          paymentData: this.paymentData,
          ratePlan: this.ratePlan,
          guaranteeType: this.rateDetailsInfo.guarantee.type
        }

        //non-interactive mode

        try {

          const resp  = await ChoiceBookingAPI.bookRoom(data)

          //interactive mode
          // const resp = await this.choiceBooking.initiate(data); 

          this.processing = false;

          if (resp.data.hasOwnProperty('status') && resp.data.status == 'OK') {

            this.bookingSummary = resp.data.data
            if(this.bookingSummary.responseType == 'Pending') { //interactive mode
              this.bookingStep = 'Pending'
              this.bookingTransactionID = resp.data.transaction_identifier
            } 

            if(this.bookingSummary.responseType == 'Committed') { //non-interactive mode
              this.$root.$emit('checkoutFinished')
              this.sendGTMEventPurchase(this.r); // send datalayer to google with GA4 parameters
              this.bookingStep = 'Committed'
            }
            

          } else { //Booking failed
            this.processing = false;
            
            if (resp.data.hasOwnProperty('errors')) {
              this.bookingError = resp.data.errors[0].msg
            } else {
              this.bookingError = 'Ops! Something went wrong. Please try again later'
              console.log(resp.data);
            }
            this.$noty.error(this.bookingError)
          }
          
          
        } catch (error) {
          this.processing = false;
          this.bookingError = 'The booking could not be completed.'
          this.$noty.error(this.bookingError)
          console.error(error)
        }
        


      } else {
        //An error has occurred when contacting VGS
        this.bookingError = 'There was an error when validating credit card'
        this.$noty.error(this.bookingError)
        console.error("Status", status)
        console.error("Response", response)
      }  
    },

    sendGTMEventPurchase(){ 

      const _tax = this.bookingSummary.total.amountAfterTax - this.bookingSummary.total.amountBeforeTax

      let transactionProducts = [];
      let items = [];

      this.selectedRoom.forEach( (elem) => {
        transactionProducts.push({
          sku: elem.code,
          name: elem.name,
          category: this.ratePlan,
          price: elem.rate.AmountAfterTax,
          tax: elem.rate.TaxAmount,
          priceBeforeTax: elem.rate.AmountBeforeTax,
          quantity: this.numberOfNights()
        });

        items.push({
          item_id: elem.code,
          item_name: elem.name,
          affiliation: this.getHotelName,
          currency: "USD",
          index: 0,
          item_brand: "Choice Hotels",
          item_category: this.ratePlan,
          item_category2: this.$store.state.rateFullName,
          price: elem.rate.AmountAfterTax,
          quantity: this.numberOfNights()
        });
      })

      this.sendGTMwithGA4({
        event: "purchase",

        // the old version gtm-tag with UA only, without the GA4 parameters
        // google documentation recommends keeping both ways, this and the new with GA4
        category: "Purchase",
        action: "click",
        label: "Choice Booking Widget",
        virtualPageURL: '/choice/Confirmation-GTM',
        virtualPageTitle: 'Confirmation',
        arrival_date: this.calendarData.dateRange.start,
        departure_date: this.calendarData.dateRange.end,
        currency_code: this.bookingSummary.total.currencyCode,
        transactionId: this.bookingSummary.reservationID,
        transactionAffiliation: this.getHotelName,
        transactionTotal: this.bookingSummary.total.amountAfterTax,
        transactionTax: (_tax.toFixed(2)).toString(),
        transactionShipping: '',
        transactionProducts: transactionProducts,
        noninteraction: false,

        // send datalayer to google with GA4 parameters
        transaction_id: this.bookingSummary.reservationID,
        affiliation: this.getHotelName,
        value: this.bookingSummary.total.amountAfterTax,
        tax: (_tax.toFixed(2)).toString(),
        currency: "USD",
        items: items,
        arrival: this.$store.state.searchParams.arrival,
        departure: this.$store.state.searchParams.departure,
        adults: this.$store.state.searchParams.adults,
        children : this.$store.state.searchParams.children,
        rooms: this.$store.state.searchParams.rooms
      });

    },

    async confirmBooking(){
      const resp = await this.choiceBooking.commit(this.bookingTransactionID); //bookRoom(data)
      console.log(resp)
      this.bookingStep = 'Committed'
      
    },

    printReservation(){
      var mywindow = window.open('', 'PRINT', 'height=768,width=1024');

      mywindow.document.write('<html><head><title>' + this.getHotelName  + '</title>');
      mywindow.document.write('</head><body >');
      mywindow.document.write(document.getElementById("divPrintPreview").innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      if (this.$isMobile()){
        setTimeout(function(){ mywindow.print(); }, 1000);
      } else {
        mywindow.print();
        mywindow.close();
      }
      
      return true;
    },

    cancelReservation(){

        this.$swal.fire({
        title: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: `Confirm`,
        }).then( async (result) => {
        
          if (result.isConfirmed) {
            this.processing = true
            try {
              const params = {
                reservationID: this.bookingSummary.reservationID,
                hotelCode: this.searchParams.hotelCode,
                surName: this.bookingSummary.customer.PersonName.Surname,
                email: this.bookingSummary.customer.Email._
              }
              const resp  = await ChoiceBookingAPI.cancelReservation(params)

              if (resp.data.status == 'ERROR') {
                this.processing = false
                resp.data.data.errors.forEach( error => {
                  this.$noty.error(error)
                })
              } else {
                this.processing = false
                this.$noty.success("Your reservations has been cancelled successfully.")
                this.bookingStep = 'Cancelled'
              }

            } catch (error) {    
              this.processing = false
              this.$noty.error("Oops, something went wrong!")
              console.error(error)
              
            }
          } 
        })
      
        
    },

    close(){
      this.$emit("close")
    },

    startOver(){
      this.$emit("start-over")
    }
    
  },

  filters: {
    twoDigits: function (value) {
      return String(value).padStart(2, "0");
    },
    formatDate: function(date){
      const dateObj = new Date(date + 'T00:00:00');
      return new Intl.DateTimeFormat('en-US').format(dateObj);
    },
    toMonthName: function (value) {
      if (!value) return "";
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return monthNames[value - 1];
    },
  },
};
</script>