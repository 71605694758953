import ChoiceBookingAPI from "@/api/ChoiceBookingAPI"
import find, { isSet } from 'lodash'
export default {

    data: {},
    ratePlans: {},
    baseRate: 0,

    async bookRoom(payload) {
        const resp  = await ChoiceBookingAPI.bookRoom(payload)
        return resp.data;

    },

    // async searchRates(bookingData) {
    //     const payload = {
    //         checkin: bookingData.arrival,
    //         checkout: bookingData.departure,
    //         adults: bookingData.adults,
    //         children: bookingData.children,
    //         rooms: bookingData.rooms,
    //         hotelCode: bookingData.hotelCode,
    //         promoCode: bookingData.promoCode
    //       };

    //     try {
    //         const resp  = await ChoiceBookingAPI.searchRates(payload)
    //         this.data =  resp.data
    //         if (!this.data.error)
    //             this.ratePlans = this.getRatePlans() //Load Rate Plans

    //     } catch (error) {
    //         console.log(error)
    //         return false
    //     }
        

        
        
    // },

    async getHotelData(payload){
        return await ChoiceBookingAPI.getHotelData(payload);
    },

    async getCalendarRates(payload){

        return await ChoiceBookingAPI.getCalendarRates(payload);

    },

    async initiate(payload) {
        const resp = await ChoiceBookingAPI.initiate(payload)
        return resp.data;
 
    },

    async commit(transactionID) {
        const resp = await ChoiceBookingAPI.commit(transactionID)
        return resp.data;
 
    },

    //return the rooms available based on last search query
    getAvailableRooms(ratePlanCode = "BASE"){

        let rooms = []

        this.data.roomRates.forEach( room => {
            if (room.RatePlanCode == ratePlanCode) {
                let tmpRoom = this.data.hotelData.facilityInfo.GuestRooms.GuestRoom.find ( r => r.Code == room.RoomTypeCode)

                rooms.push({
                    code: room.RoomTypeCode,
                    name: tmpRoom.RoomTypeName, //room.RoomDescription.Text._,
                    max_occupancy: tmpRoom.MaxOccupancy,
                    number_of_units: room.NumberOfUnits,
                    amenities: tmpRoom.Amenities.Amenity,
                    adults: 1,
                    children: 0,
                    isValid:true,
                    rate: room.Rates.Rate[0],
                    total: room.Total 
                })

            }

        })

        return rooms

    },

    getRatePlans(){

        console.log('getRateplans deprecated. Use booking-widget-store.js store method')

        let resp = []
        
            if (Array.isArray(this.data.ratePlans.RatePlan)) {

                this.data.ratePlans.RatePlan.forEach( ratePlan => {
                    if (ratePlan.AvailabilityStatus == 'AvailableForSale' ) {
                        // console.log('LOG: ',ratePlan.CancelPenalties.CancelPenalty.NonRefundable);
                        resp.push({
                            code: ratePlan.RatePlanCode,
                            name: (ratePlan.RatePlanDescription.Name == 'Long description') ? 'Everyday Rate' : ratePlan.RatePlanDescription.Name,
                            description: ratePlan.RatePlanDescription.Text._,
                            cancellation: {
                                text: ratePlan.CancelPenalties.CancelPenalty.PenaltyDescription.Text._,
                                deadline: ''
                            },
                            guarantee: {
                                text: ratePlan.Guarantee.GuaranteeDescription.Text._
                            },
                            default: true
                        })        
                    }
    
                })
            } else {
                resp.push({
                    code: this.data.ratePlans.RatePlan.RatePlanCode,
                    name: this.data.ratePlans.RatePlan.RatePlanDescription.Text._,
                    cancellation: {
                        text: this.data.ratePlans.RatePlan.CancelPenalties.CancelPenalty.PenaltyDescription.Text._,
                        deadline: '' 
                    },
                    guarantee: {
                        text: this.data.ratePlans.RatePlan.Guarantee.GuaranteeDescription.Text._
                    },
                    default: true
                })
            }
    
            return resp

    },

    getPricesByRateAndRoomType(rate, code){
        
        const _rate = this.data.roomRates.find( _rate => _rate.RoomTypeCode == code)
        
        return {
            rate: _rate.Rates.Rate[0].Base,
            total: _rate.Total
        }

    },

    getTotal(bookingData){

        const ratePlan = bookingData.ratePlan

        let total = {beforeTax:0, afterTax:0, taxes:0, fees:0}

        bookingData.selectedRoom.forEach( room => {
            // let _rate = this.data.roomRates.find( _rate => _rate.RoomTypeCode == code)

            if (typeof room.rate.Fees == 'object' ){
                total.fees+= Number(room.rate.Fees.Fee.Amount)    
            }
            total.beforeTax+= Number(room.total.AmountBeforeTax)
            total.afterTax+= Number(room.total.AmountAfterTax)
            total.taxes+= Number(room.total.Taxes.Amount)
            // console.log(_rate.Total)
        })

        return total

    }
}