import ChoiceBookingAPI from '../api/ChoiceBookingAPI'
import {concat, get, sortBy} from 'lodash'
import { creditCards } from '@/static/creditCards'
import Cookies from 'js-cookie'

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const checkin = urlParams.get('checkin')
const checkout = urlParams.get('checkout')
const adults = urlParams.get('adults')
const children = urlParams.get('children')
const rooms = urlParams.get('rooms')

if (urlParams.get('promoCode') !== null) {
    Cookies.set('promoCode', urlParams.get('promoCode'), { secure: true })
}
const promoCode = (Cookies.get('promoCode') !== 'null') ? Cookies.get('promoCode') : nullee


const getDefaultState = () => {
    return {
        searchParams: {
            arrival: checkin ? checkin : '',
            departure: checkout ? checkout : '',
            adults: adults ? adults : 1,
            children: children ? children : 0,
            rooms: rooms ? rooms : 1,
            promoCode: promoCode ? promoCode : '',
            hotelCode: '',
            ratePlans: ['BASE'],
            siteId: ''
        },
        roomOrder: [],
        activeRatePlan: '',
        selectedRoom: [],
        hotelData: {},
        guestData: {
            givenName: "",
            surName: "",
            email: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            country: "US",
            postalCode: "",
            memberNumber: "",
        },
        paymentData: {
            // cardCode: "AX",
            // cardNumber: "371449635398431",
            // expireDate: "1220",
            // cardHolderName: "John Doe"
            expireDateMonth: "",
            expireDateYear: "",
            card_valid:""
        },
        calendarData:{
            dateRange: {
                start: checkin ? checkin : '',
                end: checkout ? checkout : ''
            }
        },
        ratePlans: [],
        ratePlansInfo: [],
        ratePlanDefinitions: [
            {
                code: 'BASE',
                name: 'Pay at Check-in'
            }
        ],
        rateFullName: ''
    }
  }

  // initial state
const state = getDefaultState()

const store = {
    state,

    mutations: {
        setRatePlans(state, ratePlans){
            if (typeof ratePlans == 'undefined') return;
            ratePlans.forEach((elem) => {
                if (elem.length > 0 ) {
                    let elems = elem.split('|')
                    state.searchParams.ratePlans.push(elems[0])    
                }
            })
        },

        setRatePlanDescription(state, ratePlans){
            if (typeof ratePlans == 'undefined') return;
            ratePlans.forEach((elem) => {
                if (elem.length > 0 ) {
                    let elems = elem.split('|')
                    state.ratePlanDefinitions.push({code: elems[0], name: elems[1]})
                }
                
            })
            
        },
        resetData(state){
            Object.assign(state, getDefaultState())
        },
        setHotelCode(state, hotelCode) {
            state.searchParams.hotelCode = hotelCode
        },
        setSiteId(state, siteId) {
            state.searchParams.siteId = siteId
        },
        updateCalendarData(state, payload) {
            state.calendarData = payload
        },
        updateCheckinCheckout(state, payload) {
            state.searchParams.arrival = payload.arrival
            state.searchParams.departure = payload.departure
        },
        updateSearchParams(state, payload) {
            state.searchParams[payload.key] = payload.value
        },
        setHotelCode(state, hotelCode){
            state.searchParams.hotelCode = hotelCode
        },
        setActiveRatePlan(state, value) {
            state.activeRatePlan = value
        },
        setRatePlan(state, value) {
            state.ratePlans = value
        },
        setRoomOrder(state, order) {
            state.roomOrder = (order) ? order : []
        },
        setRatePlanInfo(state, value) {
            state.ratePlansInfo = value
        },
        setHotelData(state, payload){
            state.hotelData = payload
        },
        setSelectedRoom(state, payload) {
            if (state.searchParams.rooms == 1) {
                state.selectedRoom = [payload.room]    
            } else {
                state.selectedRoom.splice(payload.idx, 1, payload.room)
            }
            
        },
        setRateFullName(state, fullName){
            state.rateFullName = fullName;
        },
    },

    actions: {
        loadHotelData({commit}) {
            
            ChoiceBookingAPI.getHotelData( {
                hotelCode: this.state.searchParams.hotelCode,
                siteId: this.state.searchParams.siteId
            } )
                .then( resp => {
                try {
                    commit('setHotelData', resp.data.data)
                } catch (error) {
                    console.log(error)
                }
            })
        },

        async searchRooms({commit, state}) {

            try {
                const rates = await ChoiceBookingAPI.searchRates(state.searchParams);
                
                return new Promise(  (resolve, reject) => {
                    
                    if ( !rates || rates.data.data.status == "ERROR" ) {
                        reject(rates.data.data.msg)
                    } else {
                        // commit('setActiveRatePlan', 'BASE' )
                        commit('setRoomOrder', rates.data.data.order )
                        commit('setRatePlan', rates.data.data.stays )
                        commit('setRatePlanInfo', rates.data.data.rates )
                        resolve(true)
                    }

                } )
                

            } catch (error) {

                this.error = true;

                console.log(error)

                return false
                
            }

        }

    },

    getters: {

        getTotalAmount(state){

        let total = {beforeTax:0, afterTax:0, taxes:0, fees:0}

        state.selectedRoom.forEach( room => {
            // let _rate = this.data.roomRates.find( _rate => _rate.RoomTypeCode == code)

            if (typeof room.rate.Fees == 'object' ){
                total.fees+= Number(room.rate.Fees.Fee.Amount)    
            }
            total.beforeTax+= Number(room.total.AmountBeforeTax)
            total.afterTax+= Number(room.total.AmountAfterTax)
            total.taxes+= Number(room.total.Taxes.Amount)
            // console.log(_rate.Total)
        })

        return total


        },
        getHotelAlerts(state) {
            let alerts = [];
            const hotelPolicy = get(state.hotelData, 'Policy.PolicyInfo.Description.Text._', null); 
            const petsPolicy = get(state.hotelData, 'Policy.PetsPolicies.PetsPolicy.Description.Text._', null); 
            const serviceAlerts = get(state.hotelData, 'HotelAlerts', null); 

            if (serviceAlerts !== null || serviceAlerts.length !== 0) {
                serviceAlerts.forEach( (el) => alerts.push({type: 'serviceAlert', text: el}));
            }

            if (hotelPolicy !== null) alerts.push({ type: 'hotelPolicy', text: hotelPolicy});
            if (petsPolicy !== null) alerts.push({ type: 'petsPolicy', text: petsPolicy});

            return alerts
            
        },

        hotelData(state){
            let phone = ''
            if (state.hotelData.Phone) 
                phone = state.hotelData.Phone.find( phone => phone.PhoneTechType == "1")
            
            return {
                phone : phone.PhoneNumber, //get(state.hotelData, 'Phone[0].PhoneNumber', ''),
                checkIn : get(state.hotelData, 'Policy.PolicyInfo.CheckInTime', ''),
                checkOut : get(state.hotelData, 'Policy.PolicyInfo.CheckOutTime', ''),
                customRoomDescription: get(state.hotelData, 'CustomRoomDescription', [])
            }

        },
        // This function compares the list of credit card types received by API with the static list of creditCard.js and return a array of credit cards types that can enabled the checkout button
        creditCardList(state){
            let list = [];

            let acceptedPayment = get(state.hotelData, 'Policy.GuaranteePaymentPolicy.GuaranteePayment.AcceptedPayments.AcceptedPayment', '');
            acceptedPayment.forEach(function(paymentCard) {
                creditCards.forEach(function(item) {
                    if(item.code == paymentCard.PaymentCard.CardCode){
                        list.push({
                            code: paymentCard.PaymentCard.CardCode,
                            vgs: item.vgs,
                            name: item.name
                        });
                    }
                });
            });
            
            //console.log(list);
            return list;
        },
        getHotelName(state){
            return get(state.hotelData, 'HotelName', '')
        },
        isSearchParamsValid(state){
            const todaysDate = new Date()
            todaysDate.setHours(0,0,0,0)
            const selectedCheckin = new Date(state.searchParams.arrival)
            const selectedCheckout = new Date(state.searchParams.departure)

            //validate date selection
            return (selectedCheckin >= todaysDate) && (selectedCheckin < selectedCheckout)
        },
        getAvailableRooms(state){
            let rooms = []

            try {
                if (typeof state.ratePlans == 'undefined' || state.ratePlans.length == 0) return

                for (const [code, room] of Object.entries( state.ratePlans[state.activeRatePlan].Rooms )) {

                    let tmpRoom = state.hotelData.GuestRooms.find ( r => r.Code == code)
                    let customRoom = state.hotelData.CustomRoomDescription.find ( r => r.room_code == code)
                    
                    rooms.push({
                        code: code,
                        name: tmpRoom.RoomTypeName, //room.RoomDescription.Text._,
                        description: (customRoom) ? customRoom.room_description : '',
                        max_occupancy: tmpRoom.MaxOccupancy,
                        number_of_units: room.NumberOfUnits,
                        amenities: tmpRoom.Amenities.Amenity,
                        adults: 1,
                        children: 0,
                        isValid:true,
                        rate: room.Rates,
                        total: room.Total 
                    })
                }

                //Re-order Rooms
                if (state.roomOrder.length > 0) {
                    let ordered = []
                    state.roomOrder.forEach( (_code, _idx) => {
                        let itemIndex = rooms.findIndex( r => r.code == _code)    
                        if (itemIndex !== -1) {
                            ordered.push(rooms[itemIndex])
                            rooms.splice(itemIndex, 1); //delete(rooms[itemIndex]);
                        }
                    }) 
                    rooms = ordered.concat(rooms)
                }
            } catch (error) {

                console.log("Error while bulding available rooms")
                console.error(error)
                return false
                
            }

            // return rooms sorted by price. 
            return sortBy(rooms,
                [ function (o) { return o.rate.AmountBeforeTax }])
            
        },
        getRateDetails: (state) => (roomCode) => {
            const rateDetails = state.ratePlans[state.activeRatePlan].Rooms[roomCode].RateDetails
            return {
                additionalDetails: rateDetails.additionalDetails,
                guarantee: rateDetails.guarantee,
                cancelPenalties: rateDetails.cancelPenalties,
            }
        },
        rateDetailsInfo: (state) => {
            const rateDetails = state.ratePlansInfo[state.activeRatePlan]
            return {
                additionalDetails: rateDetails.additionalDetails,
                guarantee: rateDetails.guarantee,
                cancelPenalties: rateDetails.cancelPenalties,
                description: rateDetails.description.text
            }
        },

        getRateName: (state) => (code) => {
            let name = state.ratePlansInfo[code].description.name

            let r = state.ratePlanDefinitions.find(r => r.code == code)

            if (r && r.name) name = r.name        
            return name + " (starting at $" + Math.round(state.ratePlansInfo[code].best_rate) + ")"

        },

        getRatePlans: (state,getters) => {

            let rates = []
            let ratesToSort = []
            const ratesNotToSort = ["SSC","S3A","SGML","SGM","LSTATE","GROUP"]
            for (const [code, rate] of Object.entries( state.ratePlans)) {
                if (ratesNotToSort.includes(code)){
                    rates.push({ code: code, name: getters.getRateName(code), best_rate: parseFloat(state.ratePlansInfo[code].best_rate) })
                } else {
                    ratesToSort.push({ code: code, name: getters.getRateName(code), best_rate: parseFloat(state.ratePlansInfo[code].best_rate) })
                }                
            }

            let ratesSorted = sortBy(ratesToSort,[ function(o) { return o.best_rate; } ]) 
            return ratesSorted.concat(rates)
            
        },
        areRoomsSelected(state) {
            return state.selectedRoom.length ==
                parseInt(state.searchParams.rooms)
                ? true
                : false;
        },

    }
}

export default store