<template>
  <section v-if="apiResponse == 200 && (europe_check === true || us_check === true)">
    <component-styles></component-styles>
    <div v-if="cookie == false" class="gdpr ready" ref="gdprBanner">
      <div class="gdpr__container">
        <div class="gdpr__copy">
          <div class="gdpr__title">{{ title }}</div>
          <p v-html="message"></p>
        </div>
        <button @click.prevent="acceptCookies" ref="gdprOk">I ACCEPT</button>
      </div>
    </div>
  </section>
</template>
<script>

import ComponentStyles from './components/ComponentStyles.vue'
import ApiTambo from '../../services/api/ApiTambo'
import Helpers from '../../services/lib/helpers'

const european_countries = ['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU','IE','IT','LV','LT','LU','MT','NL','PL','PT','RO','SK','SI','ES','SE','GB']

export default {
  name: 'Gdpr',
  components:{
    ComponentStyles
  },
  props:{
    site_id : {
      type: String,
      default:'651'
    },
    site_lang : {
      type: String,
      default: 'en'
    },
    gdpr_country_iso : {
      type:String
    },
    brand:{
      type: String,
      default:'symphony'
    }
  },
  data(){
    return {
      cookie : false,
      apiResponse :'',
      title:'',
      message:'',
      enable_gdpr:0,
      europe_check:true,
      us_check:true
    }
  },
  created() {
    console.log('version 4102022');
    this.getApi();
  },
  mounted(){
    if(this.apiResponse == 200 && this.site_id != undefined) {
      this.animateBanner();
    }
  },
  methods: {
    acceptCookies(){
      let self = this;
      this.$refs.gdprBanner.classList.remove('ready');
      setTimeout(function(){
        document.cookie
        self.cookie = true;
        Helpers.setCookie('gdprBanner', 1,1);
      }, 600);
    },
    animateBanner(){
      setTimeout(function(){
        this.$refs.gdprBanner.classList.add('ready')
      }, 1500);
    },
    async getApi(){
      try {
        if(Helpers.getCookie('gdprBanner')==1)
          this.cookie = true;

        const result = await ApiTambo.getGdpr(this.site_id,this.site_lang,this.brand,window.location.origin)
        let finalData = result.data.data
        this.apiResponse = result.status
        if(this.apiResponse == 200 && this.site_id != undefined && this.cookie==false) {
          this.title = finalData.gdpr_title;
          this.message = finalData.gdpr_msg;
          this.enable_gdpr = finalData.enable_gdpr;

          //this.europe_check = inArray(this.gdpr_country_iso, european_countries) === -1 && this.enable_gdpr == 1;
          this.europe_check = european_countries.includes(this.gdpr_country_iso) && this.enable_gdpr == 1 ? true : false;
          this.us_check = this.enable_gdpr == 2 ? true : false;

          // console.log(this.europe_check,this.us_check)

        }
      } catch (error) {
        console.log(error);
      }

    }
  }
}
</script>