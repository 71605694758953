<template>
  <div class="w-step">
    <div class="step-1">
      <div class="step-1__calendar">
        <functional-calendar
          :sundayStart="true"
          :calendarRates="calendarRates"
          dateFormat="mm/dd/yyyy"
          v-model="calendarData"
          :isTypeable="true"
          :is-separately="true"
          :is-date-range="true"
          :is-multiple="true"
          :calendars-count="2"
          :disabledDates="['beforeToday']"
        ></functional-calendar>
        <a
          href=""
          @click.prevent="getCalendarRates()"
          class="step-1__calendar--flexible"
          >Flexible Dates?</a
        >
      </div>
      <div class="step-1__fields">
        <div class="step-1__fields--col">
          <div class="step-1__fields--date">
            <label for="arrival">Arrival</label>
            <input name="arrival" id="arrival" class="fields-date-selected" v-model="searchParams.arrival" placeholder="m/d/yyyy" v-on:blur="validateDate(searchParams.arrival, 'start')">
          </div>
        </div>
        <div class="step-1__fields--col">
          <div class="step-1__fields--date">
            <label for="departure">Departure</label>
            <input name="departure" id="departure" class="fields-date-selected" v-model="searchParams.departure"  placeholder="m/d/yyyy" v-on:blur="validateDate(searchParams.departure, 'end')">
          </div>
        </div>

        <div class="step-1__fields--others">
          <div class="w-entry">
            <label for="adults">Adults</label>
            <select name="adults" id="adults" v-model="adults">
              <option :value="n" v-for="n in maxAdultsSelection" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
          <div class="w-entry">
            <label for="children">Children</label>
            <select name="children" id="children" v-model="children">
              <option value="0">0</option>
              <option :value="n" v-for="n in maxChildrenSelection" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
          <div class="w-entry">
            <label for="rooms">Rooms</label>
            <select name="rooms" id="rooms" v-model="rooms">
              <option :value="n" v-for="n in maxRoomSelection" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
        </div>
        <div class="promo">
          <label for="promo" class="hidden">Special Rate/Corporate ID</label>
          <input id="promo" type="text" placeholder="Special Rate/Corporate ID" v-model="promoCode" v-on:keypress="isAlphaNum($event)" maxlength="10"/>
        </div>
        <div v-if="!$isMobile()">
          <button class="cta" :disabled="!isSearchParamsValid || processing" @click="searchRoomRates">
            <span v-if="!isSearchParamsValid"> Select Dates </span>
            <span v-else> Select Room </span>
          </button>
          <div class="clearfix"></div>
          <div class="processing" v-if="processing"><div class="loader-booking"></div>Processing ...</div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { FunctionalCalendar } from "@/modules/vue-functional-calendar"

import { calendarMixins } from "../mixins/calendarMixins"

import ChoiceBookingAPI from '@/api/ChoiceBookingAPI'

import { bookingWidgetMixins } from '../mixins/bookingWidgetMixins'

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "SelectDates",
  mixins: [bookingWidgetMixins, calendarMixins],
  components: {
    FunctionalCalendar,
  },

  data(){
    return {
      calendarRates: [],  
      processing: false
    }
  },

  mounted() {
    this.$root.$on('searchRoomRates', () => {
      this.searchRoomRates()
    })

    if (this.ratePlansInfo.length == 0 && this.isSearchParamsValid ) {
      this.searchRoomRates()
    }    

  },

  computed: {
    ...mapGetters(['isSearchParamsValid']),
    ...mapState(["searchParams","ratePlansInfo"]),
  },

  methods: {

    ...mapActions(["searchRooms"]),
    
    async searchRoomRates() {

      try {
        this.processing = true
        const resp = await this.searchRooms()  
        this.processing = false
        this.$emit("selectRoomBtnClicked")    
      } catch (error) {
        this.processing = false
        this.$noty.warning(error)
        this.$root.$emit("selectRoomError");

      }

      
    },

    validateDate(elem, pos){
      let date = elem //.target.value
      date = date.replace(/\/0+/g, '/')
      this.calendarData.dateRange[pos] = date;

      // console.log(date)
    },

    getCalendarRates(){
      if (this.calendarRates.length > 1) {
        this.calendarRates = [];
        return
      }
      ChoiceBookingAPI.getCalendarRates({
        hotelCode: this.$store.state.searchParams.hotelCode
      }).then( resp => {
        this.calendarRates = resp.data.data.data
      })

    }


  },
};
</script>