<template>
<section ref="styles" style="display:none;color:white;" >
      .popup-items img {
          vertical-align:top;
          max-width:100%;
        }
      .popup-items .button-content{
          display:inline-block;
          text-decoration:none;
          background-repeat:no-repeat;
      }
      .popup-items * {
        box-sizing:border-box;
        margin:0;
      }
      .popup-items ul {
        padding-left:0;
      }
      .popup-container .close{
        position:relative;
        z-index:2;
      }
      .popup-container .box{
        position:relative;
        z-index:1;
      }
    .popup-items .field input,
    .popup-items .field select {
        background-color:transparent;
        border:0;
    }
    /** Button border v2 */
    .popup-items button {
      border:0; 
    }
    .column-options {
      display:none;
    }
    .timer-container {
      display: flex;
      justify-content: center;
      align-items: stretch;
    }
    .timer-container .time{
      text-align: center;
      padding: 10px;
      flex: 1 1 0px;
      line-height: 1;
    }
    .timer-container .time .text{
      line-height: 1.3;
    }
    .timer-container .time .number.with-border{
      padding: 5px;
      margin-bottom: 5px !important;
    }
    .timer-container .no-left-border {
      border-left: 0 !important;
    }
    .timer-container .no-right-border {
      border-right: 0 !important;
    }
    .signup-module form .field {
        display: inline-block;
        vertical-align: top;
        margin: 10px;
        position:relative;
        
      }
    .signup-module form .field .error-span {
        position: absolute;
        top: -20px;
        left: -5px;
        background: #cd3737;
        color: white;
        font-size: 10px;
        padding: 3px;
        line-height: 1;
        border-radius: 3px;
        z-index:10;
    }
      .signup-module form .field.no-margin {
       position: absolute;
        display: block;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      }
      .signup-module form .field.columns-1 {
        width: calc(100% - 20px);
        padding: 0;
      }
      .signup-module form .field.columns-2 {
        width: calc(50% - 20px);
        padding: 0;
      }
      .signup-module form .field.columns-3 {
        width: calc(33.33% - 20px);
        padding: 0;
      }
      .signup-module form .field.inside-label{
        position:relative;
      }
      .signup-module form .field.align-left{
        text-align: left;
      }
      .signup-module form .field.align-right{
        text-align: right;
      }
      .signup-module form .field.align-center{
        text-align: center;
      }
      .signup-module form .field input,
      .signup-module form .field select,
      .signup-module form .field label {
        width:100%;
      }
    .signup-module form .field.inside-label input,
    .signup-module form .field.inside-label select{
        position: relative;
        z-index: 3;
        line-height:1;
    }
    .signup-module form .field.inside-label input::placeholder,
    .signup-module form .field.inside-label select::placeholder{
      color:transparent;
    } 
    .signup-module form .field.inside-label label {
      position: absolute;
      z-index: 1;
      line-height: 1;
      top:10px;
      left:10px;
    }
    .signup-module form .field.inside-label.moved label {
      transition:all 0.3s ease-in-out;
      top:0px !important;
      transform: translateY(-110%);
      left:5px !important;
      font-size:11px !important;
    }
    .signup-module form .gdpr-wrap {
      margin-bottom:15px;
    }
     .popup-items .alerts-content p a{
        color:inherit !important;
        transition:all 0.3s ease-in-out;
      }
      .popup-items .alerts-content p a:hover{
        opacity:0.4;
      }
    .signup-module form .field.gdpr {
      display:flex;
      align-items:center;
      width:100%;
      line-height:1;
      margin:10px;
    }
    .signup-module form .field.gdpr input[type="checkbox"]{
      display: inline-block;
      vertical-align: top;
      width: 12px;
      height: 12px;
      border-radius: 0;
      -webkit-appearance:none;
      -moz-appearance:none;
      appearance:none;
      position:relative;
    }
    .signup-module form .field.gdpr input[type="checkbox"]:before {
      content:"";
      position:absolute;
      top:1px;
      left:1px;
      color:inherit;
      transition:all 0.3s ease-in-out;
      opacity:0;
      font-family:'Arial',sans-serif;
      font-size:16px;
      line-height:0.1;
      text-align:center;
      width:0px;
      height:0px;
      border-width:4px;
      border-style:solid;
      border-color:inherit;
    }
    .signup-module form .field.gdpr input[type="checkbox"]:checked:before {
      opacity:1;
    }
    .signup-module form .field.gdpr label {
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      margin:0 0 0px 5px;
      line-height: 1.1;
      width: calc(100% - 30px);
    }
    .signup-module form .field.gdpr label a {
      color:inherit !important;
      text-decoration:underline !important;
      transition:all 0.3s ease-in-out;
    }
    .signup-module form .field.gdpr label a:hover{
      opacity:0.5;
    }
    .popup-items .pop-border {
        position:absolute;
        z-index:3
      }
      .hidden {
        position: absolute;
        display: block;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      }
    .message-form {
      text-align:center;
      padding:10px;
      
      margin:20px auto;
    }
  
    .alerts-column {
      z-index:5;
    }
    .bg-filter {
      height: 100%;
      z-index: 1;
      position: absolute;
      width: 100%;
      top:0;
    }
    .popup-submit {
      cursor:pointer;
      transition:all 0.3s ease-in-out;
      
    }
    .popup-submit:hover {
      opacity:0.5;
    }
    @media only screen and (max-width:700px){
      .popup-items .alerts-column {
        width:100% !important;
        padding:0px !important;
      }
      .popup-items .alerts-content{
        width:100% !important;
      }
      .popup-items .bg-column {
        display:none;     
      }
      .signup-module form .field.columns-1,
      .signup-module form .field.columns-2,
      .signup-module form .field.columns-3 {
          width: calc(100% - 10px);
        }
      .popup-items .alerts-content ul {
        padding-left:0;
      }
    }
</section>
</template>

<script>
export default {
  name: 'popStyles',
  mounted: function() {
    let styleTag = document.createElement('style');
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  }
}
</script>