export const creditCards = [
    {
      code: 'VI',
      vgs: 'visa',
      name: 'Visa'
    },
    {
      code: 'AX',
      vgs: 'amex',
      name: 'American Express'
    },
    {
      code: 'CA',
      vgs: 'mastercard',
      name: 'Master Card'
    },
    {
      code: 'MC',
      vgs: 'mastercard',
      name: 'Master Card'
    },
    {
      code: 'DS',
      vgs: 'discover',
      name: 'Discover Card'
    },
    {
      code: 'CB',
      vgs: 'dinersclub',
      name: 'Carte Blanche'
    },
    {
      code: 'JC',
      vgs: 'jcb',
      name: 'JCB Card'
    },
    {
      code: 'DC',
      vgs: 'dinersclub',
      name: 'Diners Club'
    }
  ]