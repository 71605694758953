import IcePortal from "../api/IcePortal";

export default {

    images: [],

    getImagesFromPortal(id){
        IcePortal.getVisualsV2(id).then(resp => {
          if (resp.data.ErrorMessage !== 'OK') return

            const imagesRaw =
              resp.data.Property.MediaGallery.Pictures.ImagesV2
                .PropertyImageVisualsV2;
            this.prepareImages(imagesRaw);
          });
    },

    prepareImages(images) {
      this.images = []

        images.forEach(image => {
          // console.log(image.RoomTypeCodes);
          if (image.RoomTypeCodes.hasOwnProperty("RoomTypeGroup")) {
  
            if (Array.isArray(image.RoomTypeCodes.RoomTypeGroup)) {
              // console.log(image.RoomTypeCodes.RoomTypeGroup)
              image.RoomTypeCodes.RoomTypeGroup.forEach(type => {
                // console.log("Type",type)
  
                const code = type.RoomTypeCodes.RoomType.Code;
                this.prep(code);
                
                this.images[code].thumb.push(image.ThumbUrl.Url)
                this.images[code].full.push(image.DirectLinks.Url)
  
              });
  
            } else {
  
              let type = image.RoomTypeCodes.RoomTypeGroup
              const code = type.RoomTypeCodes.RoomType.Code;
              this.prep(code);
              this.images[code].thumb.push(image.ThumbUrl.Url)
              this.images[code].full.push(image.DirectLinks.Url)
              
            }
          }
        });
        console.log(this.images );
      },

      prep(code) {
        if (!this.images.hasOwnProperty(code)) {
          this.images[code] = {}
          this.images[code].thumb = []
          this.images[code].full = []
        }
      },

      getImages(roomtype, type) {
        // return this.images[roomtype][type][0] // return main photo
        
        return typeof this.images[roomtype] != 'undefined' ? this.images[roomtype][type] : ''
      },

}