import axios from 'axios'
var ajax_url = site_url + 'ajax/functions.php'

export default {

    getVisualsV2(id) {
        let params = new FormData();
        params.append("operation", "getVisuals");
        params.append("mappedid", id);
        params.append("photosize", "XL");

        return axios.post(ajax_url, params);

    },


}
