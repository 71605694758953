import axios from 'axios'
// var ajax_url = 'http://manage.symphonygit.tambo.site/public/api/choice'
var ajax_url = process.env.MIX_CHOICE_BOOKING_ENGINE_API

try {
    if (typeof process.env.MIX_CHOICE_BOOKING_ENGINE_TOKEN == 'undefined') throw("Api Token not defined") 
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.MIX_CHOICE_BOOKING_ENGINE_TOKEN    
} catch (error) {
    console.error(error)
}


export default {

    mode: '',

    bookRoom(payload){
        this.mode = 'book'
        return this.process(payload)
    },

    initiate(payload){

        this.mode = 'initiate'
        return this.process(payload)
    },

    getHotelData(payload){
        return axios.get( `${ajax_url}/hotel/${payload.hotelCode}?siteId=${payload.siteId}` )        
    },

    getCalendarRates(payload) {

        let params = new FormData()
        params.append('operation', 'get_room_rate_calendar')
        params.append('rateplan', payload.ratePlan)
        params.append('hotelcode', payload.hotelCode)

        return axios.get(`${ajax_url}/hotel/${payload.hotelCode}/rate/BASE/rate_calendar`, { params: {
            checkIn: '',
            checkOut: '',
        }})
    },

    process(payload){

        console.log('Book Payload', payload)

        const checkin = new Date(payload.bookingData.arrival)
        const checkout = new Date(payload.bookingData.departure)

        const roomCode = payload.selectedRoom.map((row) => {
            return row.code
        })
        const adultsPerRoom = payload.selectedRoom.map((row) => {
            return row.adults
        })
        const childrenPerRoom = payload.selectedRoom.map((row) => {
            return row.adults
        })
        const totalUnits = payload.selectedRoom.map((row) => {
            // totalRooms
            return 1
        })
        
        payload.selectedRoom.forEach( room => {} )

        const params = {
            'checkIn': checkin.toISOString().split('T')[0],  //converts to YYYY-MM-DD
            'checkOut': checkout.toISOString().split('T')[0], //converts to YYYY-MM-DD
            'adults': payload.bookingData.adults,
            'children': payload.bookingData.children,
            'rooms': payload.bookingData.rooms,

            'rateCode': payload.ratePlan,
            'adultsPerRoom': adultsPerRoom.join(','),
            'roomCode': roomCode.join(','),
            'totalUnits': totalUnits.join(','),

            'guaranteeType': payload.guaranteeType,

            'firstName' : payload.guestData.givenName,
            'lastName' : payload.guestData.surName,
            'email' : payload.guestData.email,
            'address1' : payload.guestData.address1,
            'address2' : payload.guestData.address2,
            'city' : payload.guestData.city,
            'state' : payload.guestData.state,
            'country ': payload.guestData.state,
            'zip' : payload.guestData.postalCode,
            'memberID': payload.guestData.memberNumber,

            'ccCode' : payload.paymentData.card_code,
            'ccNumber' : payload.paymentData.card_number,
            'ccExp' : payload.paymentData.card_exp,
            'ccHolderName' : payload.paymentData.card_holder

            // params.append('guest_data[memberNumber]', payload.guestData.memberNumber)
            
        }

        return axios.post(`${ajax_url}/hotel/${payload.bookingData.hotelCode}/book`, params);


        
    },

    commit(transactionID) {
        let params = new FormData()
        params.append('operation', "commit")
        params.append('transaction_id', transactionID)
        return axios.post(ajax_url, params)
    },

    searchRates(payload) {

        if(payload.promoCode.length > 0) payload.ratePlans.push(payload.promoCode) //add promo code if any

        const rates = payload.ratePlans.join(",")

        const checkin = new Date(payload.arrival)
        const checkout = new Date(payload.departure)

        // if promoCode add to rates array

        const params = {
            'checkIn': checkin.toISOString().split('T')[0],  //converts to YYYY-MM-DD
            'checkOut': checkout.toISOString().split('T')[0], //converts to YYYY-MM-DD
            'adults': payload.adults,
            'children': payload.children,
            'rooms': payload.rooms,
            'ratePlans': rates,
            'siteId': payload.siteId,
        }

        
        return axios.get(`${ajax_url}/hotel/${payload.hotelCode}/rates`, {params: params});

    },

    getRoomRateDetails(room, searchParams) {
        
        const hotelCode = searchParams.hotelCode
        const roomTypeCode = room.code
        const rateCode = 'BASE'
    
        const url = `${ajax_url}/hotel/${hotelCode}/room/${roomTypeCode}/rate/${rateCode}`

        const params = {
            checkIn: searchParams.arrival,
            checkOut: searchParams.departure,
            rooms: 1,
            adults: room.adults,
            children: room.children
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        return axios.get(url, { headers: headers, params: params}) //params: params,
    },

    cancelReservation(payload){
        const params = {
            reservationID: payload.reservationID,
            surName: payload.surName,
            email: payload.email
        }
        return axios.post(`${ajax_url}/hotel/${payload.hotelCode}/cancel`, params);
    }


}
