
import Vue from 'vue'
import Vuex from 'vuex'
import { entries, merge } from 'lodash'
import VueMobileDetection from "vue-mobile-detection"
import VModal from 'vue-js-modal'
import VueNoty from 'vuejs-noty'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import VueGtm from "vue-gtm";
import VueGtm from '@gtm-support/vue2-gtm';

// import 'babel-polyfill'
// import 'url-search-params-polyfill'

import 'vuejs-noty/dist/vuejs-noty.css'

export function app(name, component, customStore = {}) {

  Vue.use(VueSweetalert2);
  Vue.use(VueMobileDetection);
  Vue.use(Vuex)
  Vue.use(VModal)

  Vue.use(VueNoty,{
    timeout: 3000,
    progressBar: true,
    layout: 'bottomCenter'
  })

  if (!IS_LIVE) console.log("GTMID: " + gtm_id || '');
  try {
    Vue.use(VueGtm, {
      id: gtm_id || '', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      // queryParams: {
      //   // Add url query string when load gtm.js with GTM ID (optional)
      //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
      //   gtm_preview: "env-4",
      //   gtm_cookies_win: "x",
      // },
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
      trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  
    })    
  } catch (error) {
    console.error( "GTM not initialized. GTM_ID = " + gtm_id )
    console.error( error.message )
  }

  const node = document.querySelector(`.vue-app[vue-app=${name}]`)

  const defaultStore = { 
    state: {
      default: true
    },
    getters:{}
  }

  const store = merge({}, defaultStore, customStore)

  const props = {}
  entries(node.dataset).forEach(([key, value]) => {
    try {
      props[key] = JSON.parse(value)
    } catch (e) {
      props[key] = value
    }
  })

  const instance = {
    name: 'App',
    store: new Vuex.Store(store),
    render(h) {
      return h(
        'div',
        {
          attrs: {
            id: `app-${name}`
          }
        },
        [h(component, { props })]
      )
    }
  }

  // Vue.prototype.$config = variables

  return new Vue(instance).$mount(node)
}