<template>
<section ref="styles" style="display:none;">
      .popup-items.type-popup  .popup-container,
      .popup-items.type-exitintent .popup-container{
          background:rgba(0,0,0,0.6);
          display:flex;
          justify-content:center;
          align-items:center;
          position:fixed;
          z-index:10000;
          top:0;
          left:0;
          height:100vh;
          width:100vw;
          transition:all 0s ease 0.6s, opacity 0.6s ease-in-out 0s;
      }
      .popup-items .alerts-content {
        width:100%;
      }
      .type-popup .popup-container.is-done,
      .type-exitintent .popup-container.is-done {
        opacity:0;
        z-index:-1;
        visibility:hidden;
        transition:all 0s ease 0.6s, opacity 0.6s ease-in-out 0s;
      }
      .type-popup .popup-container .close,
      .type-exitintent .popup-container .close {
        display: block;
        float: right;
        width: 30px;
        height: 30px;
        position:relative;
        border-radius:50%;
        background:#1d1d1d;
        margin:-20px -20px -20px 0;
      }
      .type-popup .popup-container .close span,
      .type-exitintent .popup-container .close span {
        position: absolute;
        display: block;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      }
      .type-popup .popup-container .close:before,
      .type-popup .popup-container .close:after,
      .type-exitintent .popup-container .close:before,
      .type-exitintent .popup-container .close:after {
        content:"";
        position:absolute;
        width:15px;
        height:2px;
        background:white;
        top:50%;
        left:7px;
        transform-origin: 50%;
        transition:all 0.3s ease-in-out;
      }
      .type-popup .popup-container .close:before,
      .type-exitintent .popup-container .close:before {
        transform:rotate(45deg);
      }
      .type-popup .popup-container .close:after,
      .type-exitintent .popup-container .close:after {
        transform:rotate(-45deg);
      }
      .type-popup .popup-container .close:hover:before,
      .type-popup .popup-container .close:hover:after,
      .type-exitintent .popup-container .close:hover:before,
      .type-exitintent .popup-container .close:hover:after{
         transform:rotate(0deg);
         transition:all 0.3s ease-in-out;
      }
      .type-popup .popup-container .alerts-container.pop-up,
      .type-exitintent .popup-container .alerts-container.pop-up {
        position:relative;
      }
      .type-popup .popup-container .alerts-container > div:nth-child(1),
      .type-exitintent .popup-container .alerts-container > div:nth-child(1) {
        height:100%;
      }
      .type-popup .popup-container .alerts-container .alerts-column,
      .type-exitintent .popup-container .alerts-container .alerts-column {
        position: relative;
        float: left;
        height: 100%;
        z-index:4;
      }
      .type-popup .popup-container .alerts-container .alerts-column.bg-column {
        z-index:2;
      }
      .type-popup .popup-container .alerts-container .alerts-column:last-child,
      .type-exitintent .popup-container .alerts-container .alerts-column:last-child {
        margin-top: -10px;
      }
      .type-popup .popup-container .alerts-container .bg-filter,
      .type-exitintent .popup-container .alerts-container .bg-filter {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .type-popup .popup-container .alerts-column > div:nth-child(1),
      .type-exitintent .popup-container .alerts-column > div:nth-child(1) {
            height: 100%;
            
      }
      .type-popup .popup-container .alerts-column:not(.bg-column),
      .type-exitintent .popup-container .alerts-column:not(.bg-column){
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .type-popup .popup-container .alerts-column:not(.bg-column) > div:nth-child(1),
      .type-exitintent .popup-container .alerts-column:not(.bg-column) > div:nth-child(1) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap:wrap;
            height:auto;
      }
      
      
      .type-popup .popup-container .withBackground .alerts-content,
      .type-popup .popup-container .withBackground .alerts-content *,
      .type-exitintent .popup-container .withBackground .alerts-content,
      .type-exitintent .popup-container .withBackground .alerts-content * {
        width:100%;
        height:100%;
      }
      

      @media only screen and (max-width:700px){
        .type-popup .popup-container .alerts-container.pop-up,
        .type-exitintent .popup-container .alerts-container.pop-up {
          max-width:320px;
          background-position:center;
          height:auto !important;
        }
        .type-popup .popup-container .alerts-container.pop-up > div:first-child,
        .type-exitintent .popup-container .alerts-container.pop-up > div:first-child {
          height: auto;
          overflow: auto;
        }
        .type-popup .popup-container .signup-module form .field,
        .type-exitintent .popup-container .signup-module form .field {
          margin:5px;
        }
        
      }
</section>
</template>

<script>
export default {
  name: 'popupStyles',
  mounted: function() {
    let styleTag = document.createElement('style');
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  }
}
</script>