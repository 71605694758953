<template>
  <div v-if="showFooter" class="booking-footer">
    <!-- SELECT DATE -->
    <div v-if="currentStep == 'selectDate'">
      <button
        class="cta"
        :disabled="!isSearchParamsValid || processing"
        @click="searchRoomRates"
      >
        <span> {{ !isSearchParamsValid ? 'Select Dates' : 'Select Room(s)' }} </span>
      </button>
      <div class="processing" v-if="processing">Checking<br>Availability...</div>
    </div>

    <!-- SELECT ROOM -->
    <div v-if="currentStep == 'selectRoom'">
      <div class="step-2__bottom--number-accomm">
        <button class="cta" v-if="areRoomsSelected" @click.prevent="$emit('checkout')">Checkout</button>
        <div class="processing">{{ this.selectedRoom.length }} Accommodation(s) Selected</div>
      </div>
    </div>

    <!-- CHECKOUT -->
    <div v-if="currentStep == 'selectCheckout'">
      <div class="processing" v-if="processing">Processing...</div>
      <div class="step-3__cta-section" v-else>
        <button class="cta" :disabled="processingForm" @click.prevent="bookNow()">Book Now</button>
        <a class="modify processing" href="" @click.prevent="$emit('go-to-tab', 'selectRoom')">Modify</a>
        <!-- <p class="error" v-if="bookingError">{{ bookingError }}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  name: "FooterMobile",
  props: ["currentStep"],
  computed: {
    ...mapGetters(["isSearchParamsValid", "areRoomsSelected"]),
    ...mapState(["selectedRoom"]),
  },
  data() {
    return {
      processing: false,
      processingForm: false,
      showFooter: true
    };
  },

  mounted() {

    this.$root.$on('checkoutFinished', () => {
      this.showFooter = false
    })

    this.$root.$on('searchRoomRatesCompleted', () => {
      this.processing = false
    })

    this.$root.$on('selectRoomError', () => {
      this.processing = false
    })

    this.$root.$on('isCheckoutFormValid', (isValid) => {
      if (isValid) {
        this.processingForm = true
        this.$root.$emit('bookNow')
      }
      else {
        //scroll to form
        const elmnt = document.getElementById("guestinfo");
        elmnt.scrollIntoView();
      }
    })

  },

  methods: {

    async bookNow(){
      //emit validate form
      this.$root.$emit('validateCheckoutForm')
    },

    searchRoomRates() {
      this.processing = true
      this.$root.$emit('searchRoomRates')
      
    },
  },
};
</script>
