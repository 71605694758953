<template>
<section ref="styles" style="display:none;">
{{`
    .alerts-container.pop-in{
      position:fixed;
      z-index:1000;
      transition:all 0.6s ease-in-out;
    }
  
    .popin-container.with-delay .alerts-container.pop-in.top-left,
    .popin-container.with-delay .alerts-container.pop-in.bottom-left{
      left:-150%;
    }
    .popin-container.with-delay .alerts-container.pop-in.top-right,
    .popin-container.with-delay .alerts-container.pop-in.bottom-right{
      right:-150%;
    }
    .alerts-container.pop-in > div {
      position:relative;
      z-index:1;
    }
    .alerts-container.pop-in.top-left{
      left:0;
      top:${distance};
    }
    .alerts-container.pop-in.top-right{
      right:0;
      top:${distance};
    }
    .alerts-container.pop-in.bottom-left{
      left:0;
      bottom:${distance};
    }
    .alerts-container.pop-in.bottom-right{
      right:0;
      bottom:${distance};
    }
    .alerts-container.pop-in .popin-cta.arrow {
      width: 40px;
      height: 100%;
      position: absolute;
      top: 0;
      cursor:pointer;
      z-index:3;
    }
    .alerts-container.pop-in.top-left .popin-cta.arrow,
    .alerts-container.pop-in.bottom-left .popin-cta.arrow {
      left:100%;
    }
    .alerts-container.pop-in.top-right .popin-cta.arrow,
    .alerts-container.pop-in.bottom-right .popin-cta.arrow {
      right:100%;
    }

    .alerts-container.pop-in .popin-cta.cta {
      position:fixed;
      z-index:1001;
      transition:all 0.6s ease-in-out;
      transform:translateY(-90%);
      background-color:transparent !important;
    }
    .alerts-container.pop-in.top-left .popin-cta.cta,
    .alerts-container.pop-in.top-right .popin-cta.cta {
        top:30vh;
    }
    .alerts-container.pop-in.bottom-left .popin-cta.cta,
    .alerts-container.pop-in.bottom-right .popin-cta.cta {
        bottom:5vh;
    }
     .alerts-container.pop-in.top-left .popin-cta.cta,
    .alerts-container.pop-in.bottom-left .popin-cta.cta {
      left:-100%;
    }
    .alerts-container.pop-in.top-right .popin-cta.cta,
    .alerts-container.pop-in.bottom-right .popin-cta.cta {
      right:-100%;
    }

    .alerts-container.pop-in .with-image {
      position:relative;
    }
    .alerts-container.pop-in .with-image .alerts-column {
      position:relative;
      z-index:4;
      height:100%
    }
    .alerts-container.pop-in .with-image .alerts-column > div:first-child {
      height:100%;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
    }
    .alerts-container.pop-in .with-image .cta-close {
      content:"";
      position:absolute;
      width:30px;
      height:30px;
      border-radius: 50%;
      background:#222;
      top:5px;
      right:5px;
      z-index:6;
    }
    .alerts-container.pop-in .with-image .cta-close:before,
    .alerts-container.pop-in .with-image .cta-close:after {
      content:"";
      position:absolute;
      width:15px;
      height:2px;
      background:white;
      top:50%;
      left:7px;
      transform-origin: 50%;
      transition:all 0.3s ease-in-out;
    }
    .alerts-container.pop-in .with-image .cta-close:before{
        transform:rotate(45deg);
      }
    .alerts-container.pop-in .with-image .cta-close:after  {
        transform:rotate(-45deg);
    }
    .alerts-container.pop-in .with-image .cta-close:hover:before,
    .alerts-container.pop-in .with-image .cta-close:hover:after {
         transform:rotate(0deg);
         transition:all 0.3s ease-in-out;
    }


    .type-popin .to-hide .alerts-container.pop-in.top-left,
    .type-popin .to-hide .alerts-container.pop-in.bottom-left {
      left:-${left};
      transition:all 0.6s ease-in-out;
    }
    .type-popin .to-hide .alerts-container.pop-in.top-right,
    .type-popin .to-hide .alerts-container.pop-in.bottom-right {
      right:-${left};
      transition:all 0.6s ease-in-out;
    }


    .type-popin .to-hide .alerts-container.pop-in.top-left .popin-cta.cta,
    .type-popin .to-hide .alerts-container.pop-in.bottom-left .popin-cta.cta {
      left:0%;
      transition:all 0.6s ease-in-out;
    }
    .type-popin .to-hide .alerts-container.pop-in.top-right .popin-cta.cta,
    .type-popin .to-hide .alerts-container.pop-in.bottom-right .popin-cta.cta {
      right:0%;
      transition:all 0.6s ease-in-out;
    }


    .alerts-container.pop-in .icon-arrow {
      width: 20px;
      height: 2px;
      position: absolute;
      transform-origin: 0;
      top: calc(50% + 10px);
      transform: rotate(-135deg);
      left: 25px;
      transition:all 0.3s ease-in-out;
    }
    .to-hide .alerts-container.pop-in .icon-arrow {
      transform: rotate(45deg); 
      left: 10px;
      top: calc(50% - 15px);
    }
    .alerts-container.pop-in .icon-arrow:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      background-color: inherit;
      left: 0;
      transform-origin: 100% 0;
      transform: rotate(-90deg);
    }
    .alerts-container.pop-in.bottom-right .icon-arrow,
    .alerts-container.pop-in.top-right .icon-arrow {
        transform: rotate(135deg);
        transform-origin: 100% 0;
        left: -5px;
        top:calc(50% + 15px);
    }

    .to-hide .alerts-container.pop-in.bottom-right .icon-arrow,
    .to-hide .alerts-container.pop-in.top-right .icon-arrow {
        transform: rotate(-45deg);
        transform-origin: 100% 0;
        left: 5px;
        top:calc(50% - 15px);
    }
    .alerts-container.pop-in.bottom-right .icon-arrow:before,
    .alerts-container.pop-in.top-right .icon-arrow:before {
        transform: rotate(90deg);
        transform-origin: 0;
    }
    .alerts-container.pop-in > div:nth-child(1) {
      height: 100%;
    }
    .alerts-container.pop-in .alerts-column {
        position: relative;
        float: left;
        height: 100%;
    }
    .alerts-container.pop-in .alerts-column:not(.bg-column) {
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .alerts-container.pop-in .alerts-column > div:nth-child(1) {
      height: 100%;
      
    }
    .alerts-container.pop-in .alerts-column:not(.bg-column) > div:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      height:auto;
    }
    .alerts-container.pop-in .withBackground *{
      width:100%;
      height:100%;
    }

     .box.to-hide .pop-border {
       opacity : 0;
     }
     .box:not(.to-hide) .pop-border {
       opacity : 1;
       transition:opacity 0.3s ease-in 0.3s;
     }

    @media only screen and (max-width:700px){
      .alerts-container.pop-in.top-left,
      .alerts-container.pop-in.top-right,
      .alerts-container.pop-in.bottom-left,
      .alerts-container.pop-in.bottom-right  {
        left:0;
        top:0;
        max-width:100vw;
        transform: translateY(0%);
        right:inherit;
      }

      .popin-container .to-hide .alerts-container.pop-in.top-left,
      .popin-container .to-hide .alerts-container.pop-in.top-right,
      .popin-container .to-hide .alerts-container.pop-in.bottom-left,
      .popin-container .to-hide .alerts-container.pop-in.bottom-right  {
        transform: translateY(-100%);
      }
   
      .alerts-container.pop-in.top-left .popin-cta.arrow,
      .alerts-container.pop-in.top-right .popin-cta.arrow,
      .alerts-container.pop-in.bottom-left .popin-cta.arrow,
      .alerts-container.pop-in.bottom-right .popin-cta.arrow  {
        transform-origin: 50%;
        transform: rotate(-90deg);
        left: 20px;
        right: inherit;
        height: 80px;
        top: calc(100% - 20px);
      }
   

  
      .alerts-container.pop-in.bottom-left .popin-cta.cta,
      .alerts-container.pop-in.bottom-right .popin-cta.cta {
        bottom:35vh;
       transform: translateY(-100%);
      }
      .alerts-container.pop-in.top-left .popin-cta.cta,
      .alerts-container.pop-in.top-right .popin-cta.cta {
        top: 35vh;
        transform: translateY(100%);
      }
    }
    `}}
</section>
</template>

<script>
export default {
  name: 'popStyles',
  props:{
    left : {
      type: String,
      default : '400px'
    },
    distance: {
      type: String,
      default : '10vh'
    }
  },
  mounted: function() {
    let styleTag = document.createElement('style');
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  }
}
</script>