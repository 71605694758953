import ChoiceBooking from "@/classes/ChoiceBooking"
import ChoiceBookingAPI from "@/api/ChoiceBookingAPI"
import {mapGetters, mapState, mapActions} from 'vuex'

export const bookingWidgetMixins = {

    filters: {
        round: function(value) {
            return Math.round(value)
        }
    },

    data() {
        return {
            
            activeRoom: 0,
            processing: false,
            roomList: [],
            error: false,
            choiceBooking: ChoiceBooking,
            availableRooms: [],
            roomList: [],
            slickSettings: {
                // "lazyLoad": "ondemand",
                // "dots": true,
                fade: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                swipeToSlide: true,
                // "variableWidth": true
                adaptiveHeight: true,
                focusOnChange: true,
                accessibility: true
            },
        }
    },

    computed: {

        ...mapState(['searchParams','activeRatePlan','selectedRoom','guestData']),

        ...mapGetters(['getRatePlans','areRoomsSelected']),
        
        totalGuestsAllRooms() {
            let adultsTotal = 0;
            let childrenTotal = 0;

            this.selectedRoom.forEach((room) => {
                adultsTotal = adultsTotal + parseInt(room.adults);
                childrenTotal = childrenTotal + parseInt(room.children);
            });

            return {
                adults: adultsTotal,
                children: childrenTotal,
            };
        },

        currentSelected() {
            if (
                typeof this.selectedRoom[this.activeRoom] != "undefined"
            ) {
                return (
                    this.selectedRoom[this.activeRoom].code ==
                    this.roomList[this.activeRoom].code
                );
            }
            return false;
        },

        activeRoomCode() {
            try {
                return this.roomList[this.activeRoom].code;
            } catch (error) {
                return false;
            }
        },


    },

    methods: {

        ...mapActions(['loadHotelData', 'searchRooms']),

        ...mapGetters(['getAvailableRooms','creditCardList']),

        async searchRoomRates() {
            try {
              this.processing = true
              const resp = await this.searchRooms()  
              this.processing = false
              return true 
            } catch (error) {
                console.error("DEBUG: ", error)
                return false
            }
        },

        async reloadRoomRates() {
        const resp = await this.searchRoomRates();
        this.processing = false;
        if (!resp) {
            this.availableRooms = [];
            this.roomList = [];
            this.$noty.warning("No rooms found");
            this.error = true;
        } else {
            this.error = false;
            this.roomList = [];
            this.availableRooms = this.getAvailableRooms() 
            this.selectDefaultRoom();
        }
        },
      
        selectDefaultRoom(){
        // select first available room
        for (let n = 0; n < parseInt(this.searchParams.rooms); n++) {
            this.roomList.push(JSON.parse(JSON.stringify(this.availableRooms[0])));
        }
        },

        setActiveRoom(n) {
            this.activeRoom = n;
        },

        removeRoom(idx) {
            this.searchParams.rooms--;
            this.selectedRoom.splice(idx, 1);
            this.roomList.splice(idx, 1);
            this.setActiveRoom(idx - 1);
        },

        isRoomSelected(idx) {
            return typeof this.selectedRoom[idx] != "undefined";
        },

        previewRoom(room) {
            const _room = this.availableRooms.find(
                (availableRoom) => availableRoom.code == room.code
            );
            this.roomList.splice(this.activeRoom, 1, _room);
        },

        isMaxAvailableSelected(roomCode, totalAvailable) {
            let totalSelected = 0;
            this.selectedRoom.forEach((room) => {
                if (room.code == roomCode) totalSelected++;
            });
            return totalSelected == totalAvailable ? true : false;
        },

        changeRatePlanCode(event) {

            this.$store.commit('setActiveRatePlan',event.target.value)
            this.availableRooms = this.getAvailableRooms() 

            this.updateRateName();
            
            //select first avaiable room
            for (let n = 0; n < parseInt(this.searchParams.rooms); n++) {
                this.$store.commit('setSelectedRoom', { idx: n, room: this.availableRooms[0]} )
            }

            this.roomList.splice(this.activeRoom, 1, this.availableRooms[0]);
        },

        // auto select the first option available if the Rates select field is empty
        loadRateSelect(event){
            if ( $( "#view-by" ).length ) {
                let value = document.getElementById("view-by").value;
                if(value == ""){
                    let newValue = document.getElementById("view-by").options[0].value;
                    document.getElementById("view-by").value = newValue;
                    
                    const event = new Event('change');
                    const element = document.querySelector("#view-by");
                    element.addEventListener("change", function(){
                        this.reloadRoomRates();
                    });
                    element.dispatchEvent(event);
                }
            }
            return true;
        },

        updateRateName(){
            let select = document.getElementById("view-by");
            let rateOption = select.options[select.selectedIndex].text;
            let rateParts = rateOption.split("(");
            let rateFullName = rateParts[0].trim();
            this.$store.commit('setRateFullName', rateFullName);
        },

        selectRoom(room) {
            let adults = 0;
            let children = 0;
            let adultsTotal = 0;
            let childrenTotal = 0;

            const totalGuestAllRooms = this.totalGuestsAllRooms;

            this.selectedRoom.forEach(room => {
              adultsTotal = adultsTotal + parseInt(room.adults)
              childrenTotal = childrenTotal + parseInt(room.children)
            })

            if (this.searchParams.rooms > 1 ) {
                //auto assign guests to each room

                const _c = parseInt(this.searchParams.children / this.searchParams.rooms);

                if (this.activeRoom + 1 != this.searchParams.rooms) {
                    //not last
                    const _a = Math.ceil(
                        this.searchParams.adults / this.searchParams.rooms
                    );
                    const _c = Math.ceil(
                        this.searchParams.children / this.searchParams.rooms
                    );
                    adults = _a;
                    children = _c;
                } else {
                    const _a = parseInt(this.searchParams.adults / this.searchParams.rooms);
                    const _c = parseInt(
                        this.searchParams.children / this.searchParams.rooms
                    );
                    let leftAdults = this.searchParams.adults - totalGuestAllRooms.adults;
                    let leftChildren =
                        this.searchParams.children - totalGuestAllRooms.children;
                    adults = leftAdults < _a ? leftAdults : _a;
                    children = leftChildren < _c ? leftChildren : _c;
                }
            } else {
                adults = this.searchParams.adults;
                children = this.searchParams.children;
            }

            let Room = {
                code: room.code,
                name: room.name,
                total: room.total,
                max_occupancy: room.max_occupancy,
                rate: room.rate,
                adults: adults,
                children: children,
                guestname: "",
            };

            this.$store.commit('setSelectedRoom', { idx: this.activeRoom, room: Room} )
            // this.selectedRoom.splice(this.activeRoom, 1, Room);
            if (this.activeRoom + 1 < parseInt(this.searchParams.rooms))
                this.setActiveRoom(this.activeRoom + 1);

            if (this.searchParams.rooms == 1) {
                this.$emit('checkout')
            }
        },

        filterDuplicates(amenities) {

            if (!Array.isArray(amenities)) return [amenities]

            const filteredArr = amenities.reduce((acc, current) => {
                const x = acc.find(item => item.CodeDetail === current.CodeDetail);
                if (!x) {
                  return acc.concat([current]);
                } else {
                  return acc;
                }
              }, []);

            return filteredArr
        },

        numberOfNights(){
            var date1 = new Date(this.searchParams.arrival);
            var date2 = new Date(this.searchParams.departure);
            var timeDiff = Math.abs(date2.getTime() - date1.getTime());
            return Math.ceil(timeDiff / (1000 * 3600 * 24)); 
        },

        getCreditCardList(){
            return this.creditCardList();
        },

        sendGTMwithGA4(tagsObject){
            // //just in test environment
            // Object.keys(tagsObject).forEach(function(paramTag){
            //     console.log(paramTag+": "+tagsObject[paramTag]);
            // });

            try {
                this.$gtm.trackEvent(tagsObject)  
            } catch (error) {
                console.error("Failed GTM TrackEvent, ERROR: "+error.message)
            }
            
        }

    }

}