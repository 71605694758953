<template>
<section ref="styles" style="display:none;">
      .gdpr {
        position: fixed;
        bottom:0;
        left:0;
        width:calc(100vw - 20px);
        z-index: 999;
        background:rgba(0,0,0,0.75);
        padding:15px 10px;
        transform:translate(0, 70px);
        transition:all 0.5s ease-in-out 0s;
        opacity:0;
      }
      .gdpr.ready{
        opacity:1;
        transform:translate(0, 0px);
        transition:all 0.7s ease-in-out 0.2s;
      }
      .gdpr__container {
        margin:0 auto;
        width:95%;
        max-width:960px;
        padding:0 10px;
        display:flex;
        justify-content:space-between;
        align-items:center;
      }
      .gdpr__copy {
        font-family:inherit;
        padding-right:20px;
      }
      .gdpr__title {
        text-transform:uppercase;
        color:white;
        font-size:20px;
      }
      .gdpr__copy p {
        color:white;
        font-size:14px;
        line-height:1.6;
        margin:0;
      }
      .gdpr__copy p a {
        color:white;
        text-decoration:underline;
        transition:all 0.3s ease-in-out;
      }
      .gdpr__copy p a:hover {
        opacity:0.6;
        transition:all 0.3s ease-in-out;
      }
      .gdpr button {
        border-radius:0px;
        border:1px solid white;
        padding:10px 25px;
        line-height:1;
        text-transform:uppercase;
        color:white;
        font-size:16px;
        background:transparent;
        transition:all 0.3s ease-in-out;
        font-family:inherit;
        width:230px;
      }
      .gdpr button:hover {
        background:white;
        color:black;
        transition:all 0.3s ease-in-out;

      }
      @media only screen and (max-width:600px){
        .gdpr button {
          width:260px;
          padding:10px;
          font-size:3.5vw;
        }
        .gdpr__title {
          font-size:4.5vw;
        }
        .gdpr__copy p {
          font-size:3vw;
        }
      }
</section>
</template>

<script>
export default {
  name: 'ComponentStyles',
  mounted: function() {
    let styleTag = document.createElement('style');
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  }
}
</script>