import axios from 'axios'
const endpoint_api = process.env.VUE_APP_ENDPOINT_API
export default{
    getEndpointApi(brand){
        return `${endpoint_api}${brand}/`;
    },
    getGdpr(site_id,site_lang,brand,host){ 
        if(site_lang=="") site_lang = "en";
        const  cacheKey = "gdpr_"+site_id+"_"+site_lang,
               resp     = window.sessionStorage.getItem(cacheKey),
               hostOri  = window.btoa(unescape(encodeURIComponent(host))),
               url      = this.getEndpointApi(brand)+`siteOptions/${site_id}/getGdprBySiteId/${site_lang}/${hostOri}`;

        return this.generateSessionStorage(cacheKey,resp,url,'get');
    },
    getPopups(site_id,site_lang,is_mobile,filter,brand){
        
        let    cacheKey = "popup_"+site_id+"_"+site_lang,
               resp     = window.sessionStorage.getItem(cacheKey),
               filters  = window.btoa(unescape(encodeURIComponent(filter))).replace(/\+/g, '.').replace(/\//g, '_'),
               url      = this.getEndpointApi(brand)+`popups/${site_id}/getPopupBySiteId/${site_lang}/${is_mobile}/${filters}`,
               dataStorage = JSON.parse(resp);
        
        if( dataStorage !== null ){
            delete dataStorage.data.data.filters.site_id
            delete dataStorage.data.data.filters.is_mobile
            delete dataStorage.data.data.filters.lang
            if(filter!==JSON.stringify(dataStorage.data.data.filters)){
                resp = null;
                window.sessionStorage.removeItem(cacheKey);
            } 
        }

        return this.generateSessionStorage(cacheKey,resp,url,'get');
    },
    setContent(contentId,content,field,contentType,split){
        let params = new URLSearchParams();
        params.append("split", split);
        params.append("contentType", contentType);
        params.append("contentId", contentId);
        params.append("content", content);
        params.append("field", field);
        return axios.post(`${endpoint_api}setContent`,params);
    },
    setPopupsImpression(id,brand,siteId){
        const  params   = new URLSearchParams(),
               url      = `${site_url}ajax/functions.php` //this.getEndpointApi(brand)+`popups/impressions`;
        
        params.append("popupId", id);
        params.append("brand", brand);
        params.append("siteId",siteId);
        params.append("operation", 'exitIntentImpression');
        return axios.post(url,params);
    },
    sendFormSignup(params){
        return axios.post(`${site_url}ajax/functions.php`,params);
    },
    generateSessionStorage(cacheKey,resp,url,method){
        if (resp) {
            return Promise.resolve(JSON.parse(resp));
        } else {
            return axios.get(url)
            .then(response => {
                window.sessionStorage.setItem(cacheKey, JSON.stringify(response));
                return response;
            }).catch(error => {
                return error;
            });
        }
    }
}