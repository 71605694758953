import { app } from './lib/app'
import BookingWidget from './components/BookingWidget/BookingWidget'
import store from './store/booking-widget-store'


app('booking-widget', BookingWidget, store)

//Add the following lines to the PHP Shortcode

// <?= vue_app('booking-widget', [])  ?>
// <script src="<?= FRONTEND_ROOT . '/includes/vuejs/dist/vendor.js' ?>"></script>
// <script src="<?= FRONTEND_ROOT . '/includes/vuejs/dist/manifest.js' ?>"></script>
// <script src="<?= FRONTEND_ROOT . '/includes/vuejs/dist/booking-widget.js' ?>"></script>

