<template>
  	<div id="top-widget">
    	<button style="display:none" id="btnShowBookingWidget" v-on:click="showBookingWidget">Book Now</button>
      <button @click="closeBookingWidget" id="btnClose" href="#"><span class="hidden">close</span></button> 
		<transition name="fade">
			<div class="booking-widget-container" id="booking-widget-container" v-if="visible">
				<div class="w-nav">
					<div class="container">
					<div class="w-nav__step" :class="currentStep == 'selectDate' ? 'active' : ''" @click.prevent="goToStep('selectDate')"><a href="#" class="beBtnNav">Select Date</a></div>
					<div class="w-nav__step" :class="currentStep == 'selectRoom' ? 'active' : ''" @click.prevent="goToStep('selectRoom')"><a href="#" class="beBtnNav" >Select Room</a></div>
					<div class="w-nav__step" :class="currentStep == 'selectCheckout' ? 'active' : ''" @click.prevent="goToStep('selectCheckout')"><a href="#" class="beBtnNav" >Checkout</a></div>
				</div>
			</div>
  
			<!-- Step 1 -->
      <select-dates @selectRoomBtnClicked="selectRoom" v-if="currentStep == 'selectDate'" />
			
			<!-- Step 2 -->
			<select-room-desktop @noRoomAvailable="noRoomAvailable" @checkout="checkout" v-if=" currentStep == 'selectRoom' && !$isMobile()" :icePortal="icePortal" />
			<select-room-mobile @noRoomAvailable="noRoomAvailable" @checkout="checkout" v-if=" currentStep == 'selectRoom' && $isMobile()" :icePortal="icePortal" />

      <!-- Step 3 -->
      <checkout :icePortal="icePortal" @go-to-tab="goToStep" v-if="currentStep == 'selectCheckout'" @close="close" @start-over="startOver" />

		  

		  <div v-if="!$isMobile()" class="booking-widget-footer clearfix">
        <div class="booking-widget-footer__container">
          <div class="booking-widget-footer__mkt">&nbsp;</div>
          <ul class="booking-widget-footer__reservations">
            <li><span>Reservations: </span><a :href="`tel:+${hotelData.phone}`">{{ hotelData.phone }}</a></li>
            <li><span>Check-in: </span>{{ hotelData.checkIn | time }}</li>
            <li><span>Check-out: </span>{{ hotelData.checkOut | time }}</li> 
          </ul>
        </div>
      </div>

        <FooterMobile
          :currentStep="currentStep"
          v-if="$isMobile()"
          @checkout="checkout"
          @go-to-tab="goToStep"
        />
        
      </div>
      
    </transition>

  </div>
</template>

<script>
import IcePortal from "@/classes/IcePortal"
import ChoiceBooking from "@/classes/ChoiceBooking"

import { BookingWidgetConfig } from './config'

//Views
import SelectDates from './partials/SelectDates'
import SelectRoomDesktop from './partials/SelectRoomDesktop'
import SelectRoomMobile from './partials/SelectRoomMobile'
import Checkout from './partials/Checkout'
import FooterMobile from './partials/FooterMobile'


import { mapGetters, mapState, mapMutations } from 'vuex'

// optional style for arrows & dots
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "BookingWidget",
  components: {
    SelectDates,
    SelectRoomDesktop,
    SelectRoomMobile,
    Checkout,
    FooterMobile
  },

  props: [
    'config',
    'hotelCode',
    'ratePlans',
    'bookingUrl',
    'gtmId',
    'siteId'
  ],

  data() {
    return {
      // hotelCode: 'AZ385', //AZ385,TX482 or SE097
      icePortal: IcePortal,
      visible: false,
      triggerHash: "#_booking",
      currentStep: "selectDate",
      nonModalNodes:[]
    };
  },

  filters: {
    time: function(date){
      if (!date) return ''
      const time = date.split(":")
      
      const hours = ((parseInt(time[0]) + 11) % 12 + 1);
      const suffix = parseInt(time[0]) >= 12 ? "PM" : "AM"

      return `${hours}:${time[1]} ${suffix}`
    },

   
  },

  mounted() {

    this.$store.commit('setSiteId', this.siteId)

    this.$store.commit('setHotelCode', this.hotelCode)

    this.$store.commit('setRatePlans', this.ratePlans)

    this.$store.commit('setRatePlanDescription', this.ratePlans)

    //Load VGS Script
      let vgsScript = document.createElement('script')

      const vgsScriptURL = (IS_LIVE) ? 'https://js.verygoodvault.com/vgs-collect/2.2.1/vgs-collect.js' : 'https://js.verygoodvault.com/vgs-collect/1/ACrSrXXcpJtQnicaRhQcCp3c.js'

      vgsScript.setAttribute('src', vgsScriptURL)
      document.head.appendChild(vgsScript)

    //Add this component directly to the DOM
    document.getElementById("bookingWidgetBtn").appendChild(this.$el);

    const hash = location.hash;
    if (hash == this.triggerHash) {
      this.showBookingWidget()
    }

    //listen to hashtag changes
    window.onhashchange = (e, elm) => {
      this.hashChange();
    };

  },

  watch: {
    'bookingData.hotelCode': function(hotelCode){
      this.icePortal.getImagesFromPortal(hotelCode)
    },
  },

  computed: {
    ...mapGetters(['hotelData','isSearchParamsValid']),
    ...mapState(['selectedRoom','searchParams','ratePlansInfo'])
  },

  methods: {

    ...mapMutations(['resetData']),

    startOver(){
      //reset data
      // this.resetData()
      this.currentStep = "selectDate"
    },

    close(){
      this.closeBookingWidget()
      // this.resetData()
      this.currentStep = "selectDate"
    },

    selectRoom(){
      this.currentStep = "selectRoom"
      this.$root.$emit('searchRoomRatesCompleted')
    },

    async checkout(){
      this.currentStep = 'selectCheckout'
    },

    noRoomAvailable(){
      alert('No Room Available')
      this.currentStep = "selectDate";
    },

    goToStep(step) {
      if (this.currentStep == step) return

      switch (step) {
        case 'selectDate':
          
          break;
        case 'selectRoom':          
          if (!this.isSearchParamsValid || this.ratePlansInfo.length == 0) return
          break; 
        case 'selectCheckout':
          if (!this.isSearchParamsValid || this.selectedRoom.length == 0) return

          
          break;
      
        default:
          return
          break;
      }

      this.currentStep = step;
      
    },

    hashChange() {
      const hash = location.hash;

      if (hash != this.triggerHash) return;

      var beBtns = document.getElementsByClassName("booking-widget");

      let btnBookNow;

      //loop through all elements in array to find the exact hash match. The we will get all data attributes
      //from this element and pass to the component
      for (const elem of beBtns) {
        if ((elem.href = hash)) {
          btnBookNow = elem;
          break;
        }
      }

      // article.dataset.promo // "3"
      this.showBookingWidget();
    },

    isIE() {
        const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
        const msie = ua.indexOf('MSIE '); // IE 10 or older
        const trident = ua.indexOf('Trident/'); //IE 11

        return (msie > 0 || trident > 0);
    },

    showBookingWidget() { 

      if (this.isIE()) {
        console.log("Internet Exporer detected! Redirecting to Booking Url")
        window.location.href = this.bookingUrl
        return 
      } 

      console.log("Displaying Booking Widget")
      
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      document.getElementById("btnClose").style.display = 'block';
      document.querySelector('.booking-widget').style.display = 'none';
      document.querySelector('.header').classList.add('top-up');
      document.body.style.overflow = "hidden";

      this.hideTabIndex();
      this.$store.commit('setHotelCode', this.hotelCode)
      this.$store.dispatch('loadHotelData')

      // Load all images related to the current property
      this.icePortal.getImagesFromPortal(this.hotelCode)


      this.visible = true;
      document.body.style.overflow = "hidden";
    },

    closeBookingWidget() {
      document.getElementById("btnClose").style.display = 'none';
      document.querySelector('.booking-widget').style.display = 'block';
      document.querySelector('.header').classList.remove('top-up'); 
      document.body.style.overflow = "";
      history.replaceState({}, document.title, "."); //Remove all hastags from url
      this.visible = false;

      this.showTabIndex();

      
    }, 

    hideTabIndex() {
      var modalNodes = Array.from( document.querySelectorAll('booking-widget-container *') );

      // by only finding elements that do not have tabindex="-1" we ensure we don't 
      // corrupt the previous state of the element if a modal was already open
      this.nonModalNodes = document.querySelectorAll('body *:not(booking-widget-container):not([tabindex="-1"])');

      for (var i = 0; i < this.nonModalNodes.length; i++) {
        var node = this.nonModalNodes[i];

        if (!modalNodes.includes(node)) {

          // save the previous tabindex state so we can restore it on close
          node._prevTabindex = node.getAttribute('tabindex');
          node.setAttribute('tabindex', -1);

          // tabindex=-1 does not prevent the mouse from focusing the node (which
          // would show a focus outline around the element). prevent this by disabling
          // outline styles while the modal is open
          // @see https://www.sitepoint.com/when-do-elements-take-the-focus/
          node.style.outline = 'none';
        }
      }
    },

    showTabIndex() {
      // close the modal and restore tabindex

      document.body.style.overflow = null;

      
      // restore or remove tabindex from nodes
      for (var i = 0; i < this.nonModalNodes.length; i++) {
        var node = this.nonModalNodes[i];
        if (node._prevTabindex) {
          node.setAttribute('tabindex', node._prevTabindex);
          node._prevTabindex = null;
        }
        else {
          node.removeAttribute('tabindex');
        }
        node.style.outline = null;
      }
    }
  },

  customAlert(msg, type = null) {
    alert(msg);
  }
};

// Let the document know when the mouse is being used
document.body.addEventListener('mousedown', function() {
  document.body.classList.add('using-mouse');
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener('keydown', function(event) {
  if (event.keyCode === 9) {
    document.body.classList.remove('using-mouse');
  }
});

</script>

<style lang="scss" scope>
@import "~/booking-widget.scss";
iframe{height: 37px; width:100%;overflow: hidden;}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

// .beBtnNav {display: block;}

</style>

<style lang="scss">
@import "~/_calendar.scss";
</style>