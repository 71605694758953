<template>
  <div class="w-step">
    <div class="loading" v-if="processing">
      <img
        src="https://admin.symphonyhotelmarketing.com/includes/images/loading.gif"
      />
    </div>
    <div class="step-2">
      <div class="step-2__top" v-if="!processing && !error">
        <div class="step-2__top--mkt"></div>
        <div class="step-2__top--form">
          <div class="w-entry date">
            <label for="check-in">Arrival: </label>
            <label for="check-out">Departure: </label>
            <functional-calendar
              :sundayStart="true"
              :calendarRates="[]"
              :isModal="true"
              dateFormat="mm/dd/yyyy"
              v-model="calendarData"
              :isTypeable="false"
              :is-separately="true"
              :is-date-range="true"
              :is-multiple="true"
              :isAutoCloseable="true"
            ></functional-calendar>
            <!-- <label>Arrival</label>
								<div class="step-2__top--date">{{ searchParams.arrival }}</div> -->
          </div>
          <!-- <div class="w-entry date">
							<label>Departure</label>
							<div class="step-2__top--date">{{ searchParams.departure }}</div>
							</div> -->
          <div class="w-entry"> 
            <label>Adults</label>
            <select name id v-model="adults">
              <option :value="n" v-for="n in maxAdultsSelection" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
          <div class="w-entry">
            <label>Children</label>
            <select name id v-model="children">
              <option value="0">0</option>
              <option :value="n" v-for="n in maxChildrenSelection" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
          <div class="w-entry">
            <label>Rooms</label>
            <select name id v-model="rooms" @change="updateNumberOfRooms">
              <option :value="n" v-for="n in maxRoomSelection" :key="n">
                {{ n }}
              </option>
            </select>
          </div>
          <div class="w-entry">
            <button @click.prevent="searchRoomRates()">Modify</button>
          </div>
        </div>
      </div>
      <div class="step-2__bottom" v-if="!processing && !error">
        <div class="step-2__bottom--results">
          <div class="step-2__bottom--filter">
            <label>Select Rate</label>
            <select name id v-model="ratePlan" @change="changeRatePlanCode">
              <option
                :data-selected="rate.default"
                v-for="rate in getRatePlans"
                :key="rate.code"
                :value="rate.code"
              >
                {{ rate.name }}
              </option>
            </select>
          </div>

          <div class="available-rooms">
            <div class="available-rooms__each-room" v-for="(_room, idx) in availableRooms" :key="_room.code">
              <div class="step-2__room--image">
                <div class="photo-slider">
                  <VueSlickCarousel
                    v-bind="slickSettings"
                    v-if="icePortal.getImages(_room.code, 'full').length > 0"
                  >
                    <div
                      v-for="(image, key) in icePortal.getImages( _room.code, 'full' )"
                      :key="key"
                      v-bind:style="{ 'background-image': 'url(' + image + ')', }"
                      class="photo-slider__each-img"
                    >
                      
                      <a
                        v-bind:href="image"
                        v-bind:data-fancybox-group="_room.code + idx"
                        class="fancybox photo-slider__gallery"
                        ><span class="hidden">{{ _room.name }} Gallery</span></a
                      >
                    </div>
                  </VueSlickCarousel>
                  <div v-else>
                    <img
                      src="https://frontend.tambo.site/assets/images/image-not-available.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              
              <div class="step-2__bottom--results--info">
                <div class="step-2__bottom--each-room">
                  <div class="room_name">{{ _room.name }}</div>
                  <p class="step-2__bottom--price">
                    <!-- {{ choiceBooking.getPricesByRateAndRoomType(searchParams.ratePlan, room.code).rate.AmountBeforeTax | round }} / night</p> -->
                    <span>${{ _room.rate.AmountBeforeTax | round }} / night</span>
                  </p> 

                  <div class="step-2__bottom--mobile-occupancy">
                    <p class="occupancy">Max occupancy: <span>{{ _room.max_occupancy }}</span></p>
                    <div class="room__amenities-select--amenities">
                      <a @click.prevent="showModal(_room)" class="popup-trigger">See Room Amenites</a>
                    </div>
                  </div>
                  
                  <div class="step-2__bottom--mobile-description" v-if="_room.description && _room.description.length > 0 ">
                    <span v-html="_room.description"></span>
                  </div>

                  <div class="room__amenities-select">
                    
                    
                    <div class="room__amenities-select--selects">
                      <div class="room__amenities-select--each">
                        <label>Adults</label>
                        <select name id v-model="adults">
                          <option :value="n" v-for="n in maxAdultsSelection" :key="n">
                            {{ n }}
                          </option>
                        </select>
                      </div>
                      <div class="room__amenities-select--each">
                        <label>Children</label>
                        <select name id v-model="children">
                          <option value="0">0</option>
                          <option :value="n" v-for="n in maxChildrenSelection" :key="n">
                            {{ n }}
                          </option>
                        </select>
                      </div>
                      <div class="room__amenities-select--each">
                        <label><span class="hidden">Rooms</span><br></label>
                        <select name id="rooms" @change="selectRoomMobile($event, _room)">
                          <option value="0">Select Room</option>
                          <option :value="n" v-for="n in maxRoomSelection" :key="n">{{ n }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!$isMobile()" class="step-2__bottom--number-accomm">
              <p>{{this.selectedRoom.length}} Accommodation(s)</p>
              <button class="cta" v-if="areRoomsSelected" @click.prevent="$emit('checkout')" >
                Checkout
              </button>
            </div>
            <modal name="modal-popup">
              <div class="each-modal">
                <div @click="$modal.hide('modal-popup')" class="close-modal">Close</div>
                <div class="step-2__room--amenities-title">
                  Room Amenities
                </div>
                <ul class="each-modal-list">
                  <li v-for="(amenity, idx) in filterDuplicates(amenities)" :key="idx">{{ amenity.CodeDetail }}</li>
                </ul>

              </div>
            </modal>
          </div>
        </div>
        <!-- Room Details -->
      </div>
    </div>
  </div>
</template>

<script>
import ChoiceBooking from "@/classes/ChoiceBooking";
import { FunctionalCalendar } from "@/modules/vue-functional-calendar";
import { BookingWidgetConfig } from "../config";
import VueSlickCarousel from "vue-slick-carousel";

import { bookingWidgetMixins } from "../mixins/bookingWidgetMixins";
import { calendarMixins } from '../mixins/calendarMixins'

export default {
  name: "SelectRoomMobile",
  components: {
    FunctionalCalendar,
    VueSlickCarousel,
  },
  mixins: [bookingWidgetMixins, calendarMixins],
  props: ["icePortal"],
    watch: {
    calendarData: {
      handler(newVal) {
        // refresh rates on calendarData change
        if (newVal.dateRange.start.length > 0 && newVal.dateRange.end.length > 0) this.reloadRoomRates()
      },
      deep: true,
    },
  },

  created() {
    if (this.promoCode.length > 0) {
      if (this.getRatePlans.find(rate => rate.code == this.promoCode) ) {
        this.ratePlan = this.promoCode 
      }
    } else {
      this.ratePlan = this.getRatePlans[0].code
    }
    
    this.availableRooms = this.getAvailableRooms();
    setTimeout(() => {
      document.getElementById('booking-widget-container').scrollTo(0, -57)
    }, 100);
  },
  data(){
    return {
      amenities: []
    }
  },
  methods: {
    selectRoomMobile(event, room){
      this.selectRoom(room);
      
      // const nrRooms = event.target.value
      // console.log("Event: ", event)
      // console.log("Room:", room)
      
    },
    showModal(room){
      this.amenities = room.amenities
      // console.log(elem)
      this.$modal.show('modal-popup')
    }
  }
};

</script>