<template>
<section ref="styles" style="display:none;">
   .type-alert .alert-container {
       position: fixed;
        top: 0;
        width: 100vw;
        left: 0;
        z-index: 99;
        transition:all 0.3s ease-in-out;
   }
   .type-alert .alert-container.fixed-top.moved{
       transform:translateY(0%);
   }
   .type-alert .alert-container.fixed-top.moved.is-done{
       transform:translateY(-100%);
   }
   .type-alert .alert-container.no-fixed.moved{
       transform:translateY(-100%);
   }
   .type-alert .alerts-column > div{
       display:flex;
       justify-content:center;
       align-items:center;
       flex-wrap:wrap;
   }
   .type-alert .alerts-container.alert.withclose .alerts-column > div:nth-child(1){
       padding-right:40px;
   }
   .type-alert .alerts-container.alert .alert-close {
       position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.9);
        top: calc(50% - 15px);
        right: 10px;
   }
   .type-alert .alerts-container.alert .alert-close:before,
   .type-alert .alerts-container.alert .alert-close:after {
       content: "";
        position: absolute;
        top: 50%;
        transform-origin: 50%;
        left: 7px;
        width: 15px;
        height: 1px;
        background: white;
   }
   .type-alert .alerts-container.alert .alert-close:before {
       transform: rotate(45deg);
   }
   .type-alert .alerts-container.alert .alert-close:after {
       transform: rotate(-45deg);
   }
   .type-alert .is-done{
       transform:translateY(-100%);
       transition:all 0.4s ease-in-out;
   }
   .type-alert .alerts-column {
       max-width:1170px;
       margin:0 auto;
       position:relative;
       z-index:5;
   }
   
    @media only screen and (max-width:700px){
        .type-alert .alert-container.moved {
            transform:translateY(-100%);
            
        }
        .type-alert .alerts-column > div{
            flex-direction:column;
        }
        .type-alert .alerts-container {
            height:auto !important;
        }
        .type-alert .alerts-content {
            padding:2px 0;
        }
        .type-alert .alerts-container.alert .alert-close {
            top:10px;
        }
        .type-alert .alerts-column {
            padding:10px !important;
        }
        .type-alert .alerts-container.alert.withclose .alerts-column > div:nth-child(1){
            padding-right:0px;
        }
    }
</section>
</template>

<script>
export default {
  name: 'popStyles',
  mounted: function() {
    let styleTag = document.createElement('style');
    styleTag.textContent = this.$refs.styles.textContent;
    this.$refs.styles.textContent = null;
    this.$refs.styles.appendChild(styleTag);
  }
}
</script>