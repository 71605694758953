import {mapGetters, mapState} from 'vuex'



export const calendarMixins = {
    data(){
        return {
            // calendarData: {},
            calendarRates: [],
            maxAdultsSelection: 9,
            maxChildrenSelection: 9,
            maxRoomSelection: 9,
        }
    },

    computed: {
        ...mapGetters(['isSearchParamsValid']),
        calendarData:{
            get(){
                return this.$store.state.calendarData
            },
            set(calendar){
                this.$store.commit('updateCalendarData', calendar)
            }
        },
        adults: {
            get() {
                return this.$store.state.searchParams.adults
            },
            set(value) {
                this.$store.commit('updateSearchParams', { key: 'adults', value: value })
            }
        },

        children: {
            get() {
                return this.$store.state.searchParams.children
            },
            set(value) {
                this.$store.commit('updateSearchParams', { key: 'children', value: value })
            }
        },

        rooms: {
            get() {
                return this.$store.state.searchParams.rooms
            },
            set(value) {
                this.$store.commit('updateSearchParams', { key: 'rooms', value: value })
            }
        },

        promoCode: {
            get() {
                return this.$store.state.searchParams.promoCode
            },
            set(value) {
                this.$store.commit('updateSearchParams', { key: 'promoCode', value: value })
            }
        },

        ratePlan: {
            get() {
                return this.$store.state.activeRatePlan
            },
            set(value) {
                this.$store.commit('setActiveRatePlan', value)
            }
        },
    },

    watch: {
        calendarData: {
            handler(val) { 
                this.$store.commit('updateCheckinCheckout', {
                    arrival: this.calendarData.dateRange.start,
                    departure: this.calendarData.dateRange.end
                })
            },
            deep: true
          },
        //   searchParams: {
        //     handler(val) {
        //       console.log(val)
        //       this.calendarData.dateRange.start = val.arrival;
        //       this.calendarData.dateRange.end = val.departure;
        //     },
        //     deep: true
        //   }
    },

    methods: {
        updateNumberOfRooms(val) {
            try {
                if (this.availableRooms.length == 0) throw "No Rooms Available";

                this.roomList = [];
                const nr = parseInt(val.srcElement.value);
                for (let n = 1; n <= nr; n++) {
                    this.roomList.push(
                        JSON.parse(JSON.stringify(this.availableRooms[0]))
                    );
                }
            } catch (error) {
                console.log(error);
                return;
            }
        },

        isAlphaNum(e) {
            let char = String.fromCharCode(e.keyCode)
            if(/^[A-Za-z0-9]+$/.test(char)) return true
            else e.preventDefault()
        }

        
    }
}